/**=====================
     17. List CSS Start
==========================**/
.list-group-item.active {
	background-color: $primary-color ;
	border-color: $primary-color ;
}
.list-group{
	i{
		margin-right: $list-group-margin;
	}
	img{
		height: 40px;
		width: 40px;
		margin-right: $list-group-margin;
	}
}
.list-inline-item {
	&:first-child {
		border-left: 2px solid #e5e5e5;
		padding-left: 0.5rem;
		margin-left: 0.5rem;
	}
	&:not(:last-child) {
		margin-right: 1rem;
	}
	a {
		&:hover {
			.icon {
				color: #fc813d;
			}
		}
	}
}
/**=====================
     17. List CSS Ends
==========================**/