.bg-ilustracion, .bg-login-ilustracion, .bg-buzonrojo-ilustracion {
    position: relative;
    overflow: hidden;
}
.bg-ilustracion, .bg-login-ilustracion {
    background-color: #ffeee5;
}
.bg-ilustracion {
    .animation-wrapper {
        overflow: hidden;
        >div {
            position: absolute;
        }
        .z1 {
            z-index: 1;
        }
        .z2 {
            z-index: 2;
        }
        .z3 {
            z-index: 3;
        }
        .z4 {
            z-index: 4;
        }
        .bg-shape {
            z-index: 0;
        }
    }
}
.bg-login-ilustracion, .bg-buzonrojo-ilustracion {
    .animation-wrapper {
        overflow: hidden;
        .usuario, .usuario-mail-sobre, .usuario-brazo, .usuario-mail, .usuario-sombra, .dialogo, .buzon, .buzon-plantas, .nubes, .bg-shape, .buzon-publico {
            position: absolute;
        }
        .usuario {
            z-index: 2;
        }
        .usuario-brazo {
            z-index: 8;
        }
        .usuario-mail-sobre {
            z-index: 4;
        }
        .usuario-mail {
            z-index: 3;
        }
        .usuario-sombra {
            z-index: 1;
        }
        .dialogo {
            z-index: 4;
        }
        .buzon, .buzon-plantas {
            z-index: 4;
        }
        .nubes {
            z-index: 1;
        }
        .bg-shape {
            z-index: 0;
        }
        .buzon-publico {
            z-index: 8;
        }
    }
}
@media only screen and (min-width: 1400px) {
    .bg-login-ilustracion, .bg-buzonrojo-ilustracion {
        .animation-wrapper {
            .usuario {
                left: 80%;
                top: 70%;
                transform: translate(-80%, -70%);
            }
            .usuario-brazo {
                left: 80%;
                top: 70%;
                transform: translate(-80%, -70%);
            }
            .usuario-mail-sobre {
                left: 80%;
                top: 70%;
                transform: translate(-80%, -70%);
            }
            .usuario-mail {
                left: 80%;
                top: 70%;
                transform: translate(-80%, -70%);
            }
            .usuario-sombra {
                left: 80%;
                top: 70%;
                transform: translate(-80%, -70%);
            }
            .dialogo {
                left: 80%;
                top: 70%;
                transform: translate(-80%, -70%);
            }
            .buzon, .buzon-plantas {
                left: 20%;
                top: 70%;
                transform: translate(-20%, -70%);
            }
            .nubes {
                left: 0%;
                top: 0%;
            }
            .bg-shape {
                left: 0%;
                top: 0%;
            }
            .buzon-publico {
                left: 5%;
                top: 5%;
                transform: translate(-5%, -5%);
            }
        }
    }
    .animation-wrapper {
        .center-animation, .center-bg, .center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }
        .center-bg {
            z-index: 1;
        }
        .center-animation, .center {
            padding: 0 30px;
            width: 100%;
        }
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .bg-login-ilustracion, .bg-buzonrojo-ilustracion {
        .animation-wrapper {
            .usuario {
                right: 0%;
                top: 70%;
                transform: translate(-20%, -70%);
            }
            .usuario-brazo {
                right: 0%;
                top: 70%;
                transform: translate(-20%, -70%);
            }
            .usuario-mail-sobre {
                right: 0%;
                top: 70%;
                transform: translate(-20%, -70%);
            }
            .usuario-mail {
                right: 0%;
                top: 70%;
                transform: translate(-20%, -70%);
            }
            .usuario-sombra {
                right: 0%;
                top: 70%;
                transform: translate(-20%, -70%);
            }
            .dialogo {
                right: 0%;
                top: 70%;
                transform: translate(-20%, -70%);
            }
            .buzon, .buzon-plantas {
                left: 20%;
                bottom: 15%;
                transform: translate(-20%, -15%);
            }
            .nubes {
                left: 0%;
                top: 0%;
            }
            .bg-shape {
                left: 0%;
                top: 0%;
            }
            .buzon-publico {
                left: 5%;
                top: 5%;
                transform: translate(-5%, -5%);
            }
            .usuario, .usuario-mail-sobre, .usuario-mail, .usuario-sombra, .usuario-brazo, .dialogo {
                img {
                    width: 70%;
                }
            }
            .buzon-publico {
                left: 20%;
                transform: translateX(-10%);
                img {
                    width: 100%;
                }
            }
            .buzon, .buzon-plantas {
                img {
                    width: 55%;
                }
            }
            .bg-shape {
                img {
                    width: 80%;
                }
            }
            .nubes {
                top: 10%;
                transform: translateY(-10%);
                img {
                    width: 100%;
                }
            }
        }
    }
    .animation-wrapper {
        .center-animation, .center-bg, .center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }
        .center-bg {
            z-index: 1;
        }
        .center-animation, .center {
            padding: 0 30px;
            width: 100%;
        }
    }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .bg-login-ilustracion, .bg-buzonrojo-ilustracion {
        .animation-wrapper {
            .usuario {
                right: 0%;
                top: 70%;
                transform: translate(20%, -70%);
            }
            .usuario-brazo {
                right: 0%;
                top: 70%;
                transform: translate(20%, -70%);
            }
            .usuario-mail-sobre {
                right: 0%;
                top: 70%;
                transform: translate(20%, -70%);
            }
            .usuario-mail {
                right: 0%;
                top: 70%;
                transform: translate(20%, -70%);
            }
            .usuario-sombra {
                right: 0%;
                top: 70%;
                transform: translate(20%, -70%);
            }
            .dialogo {
                right: 0%;
                top: 70%;
                transform: translate(20%, -70%);
            }
            .buzon, .buzon-plantas {
                left: 20%;
                bottom: 15%;
                transform: translate(-20%, -15%);
            }
            .nubes {
                left: 0%;
                top: 0%;
            }
            .bg-shape {
                left: 0%;
                top: 0%;
            }
            .buzon-publico {
                left: 5%;
                top: 5%;
                transform: translate(-5%, -5%);
            }
            .usuario, .usuario-mail-sobre, .usuario-mail, .usuario-sombra, .usuario-brazo, .dialogo {
                img {
                    width: 70%;
                }
            }
            .buzon-publico {
                left: 20%;
                transform: translateX(-10%);
                img {
                    width: 100%;
                }
            }
            .buzon, .buzon-plantas {
                img {
                    width: 55%;
                }
            }
            .bg-shape {
                img {
                    width: 80%;
                }
            }
            .nubes {
                top: 10%;
                transform: translateY(-10%);
                img {
                    width: 100%;
                }
            }
        }
    }
    .animation-wrapper {
        .center-animation, .center-bg, .center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }
        .center-bg {
            z-index: 1;
        }
        .center-animation, .center {
            padding: 0 30px;
            width: 100%;
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .bg-login-ilustracion, .bg-buzonrojo-ilustracion {
        .animation-wrapper {
            .usuario {
                right: -40%;
                top: 70%;
                transform: translate(0%, -70%);
            }
            .usuario-brazo {
                right: -40%;
                top: 70%;
                transform: translate(0%, -70%);
            }
            .usuario-mail-sobre {
                right: -40%;
                top: 70%;
                transform: translate(0%, -70%);
            }
            .usuario-mail {
                right: -40%;
                top: 70%;
                transform: translate(0%, -70%);
            }
            .usuario-sombra {
                right: -40%;
                top: 70%;
                transform: translate(0%, -70%);
            }
            .dialogo {
                right: -40%;
                top: 70%;
                transform: translate(0%, -70%);
            }
            .buzon, .buzon-plantas {
                left: 20%;
                top: 70%;
                transform: translate(-20%, -70%);
            }
            .nubes {
                left: 0%;
                top: 0%;
            }
            .bg-shape {
                left: 0%;
                top: 0%;
            }
            .buzon-publico {
                left: 5%;
                top: 5%;
                transform: translate(-5%, -5%);
            }
            .usuario, .usuario-mail-sobre, .usuario-mail, .usuario-sombra, .usuario-brazo, .dialogo {
                img {
                    width: 50%;
                }
            }
            .buzon-publico {
                left: 20%;
                transform: translateX(-10%);
                img {
                    width: 100%;
                }
            }
            .buzon, .buzon-plantas {
                img {
                    width: 45%;
                }
            }
            .bg-shape {
                img {
                    width: 100%;
                }
            }
            .nubes {
                top: 10%;
                transform: translateY(-10%);
                img {
                    height: 100%;
                }
            }
        }
    }
    .animation-wrapper {
        .center-animation, .center-bg, .center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }
        .center-bg {
            z-index: 1;
        }
        .center-animation, .center {
            padding: 0;
            width: 100%;
        }
    }
}
@media only screen and (min-width: 411px) and (max-width: 767px) {
    .bg-login-form {
        img {
            height: auto;
            width: 70px;
        }
    }
    .animation-wrapper {
        height: 340px;
        .usuario {
            left: 75%;
            bottom: 3%;
            transform: translate(-75%, -3%);
        }
        .usuario-brazo {
            left: 75%;
            bottom: 3%;
            transform: translate(-75%, -3%);
        }
        .usuario-mail-sobre {
            left: 75%;
            bottom: 3%;
            transform: translate(-75%, -3%);
        }
        .usuario-mail {
            left: 75%;
            bottom: 3%;
            transform: translate(-75%, -3%);
        }
        .usuario-sombra {
            left: 75%;
            bottom: 3%;
            transform: translate(-75%, -3%);
        }
        .dialogo {
            left: 75%;
            bottom: 3%;
            transform: translate(-75%, -3%);
        }
        .buzon, .buzon-plantas {
            left: 50%;
            bottom: 3%;
            transform: translate(-50%, -3%);
        }
        .nubes {
            left: 0%;
            top: 0%;
        }
        .bg-shape {
            left: 0%;
            top: 0%;
        }
        .buzon-publico {
            left: 5%;
            top: 5%;
            transform: translate(-5%, -5%);
        }
        .usuario, .usuario-mail-sobre, .usuario-mail, .usuario-sombra, .usuario-brazo, .dialogo {
            img {
                width: 70%;
            }
        }
        .buzon-publico {
            left: 50%;
            transform: translateX(-50%);
            img {
                width: 100%;
            }
        }
        .buzon, .buzon-plantas {
            img {
                width: 40%;
            }
        }
        .bg-shape, .nubes {
            img {
                width: 50%;
            }
        }
    }
    .animation-wrapper {
        .center-animation, .center-bg, .center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }
        .center-bg {
            height: 100%;
            z-index: 1;
            img {
                width: auto;
                height: 100%;
            }
        }
        .center-animation {
            padding: 30px 0;
            height: 100%;
        }
    }
}
@media only screen and (max-width: 410px) {
    .bg-login-form {
        img {
            height: auto;
            width: 70px;
        }
    }
    .animation-wrapper {
        height: 230px;
        .usuario {
            left: 75%;
            bottom: 3%;
            transform: translate(-75%, -3%);
        }
        .usuario-brazo {
            left: 75%;
            bottom: 3%;
            transform: translate(-75%, -3%);
        }
        .usuario-mail-sobre {
            left: 75%;
            bottom: 3%;
            transform: translate(-75%, -3%);
        }
        .usuario-mail {
            left: 75%;
            bottom: 3%;
            transform: translate(-75%, -3%);
        }
        .usuario-sombra {
            left: 75%;
            bottom: 3%;
            transform: translate(-75%, -3%);
        }
        .dialogo {
            left: 75%;
            bottom: 3%;
            transform: translate(-75%, -3%);
        }
        .buzon, .buzon-plantas {
            left: 50%;
            bottom: 3%;
            transform: translate(-50%, -3%);
        }
        .nubes {
            left: 0%;
            top: 0%;
        }
        .bg-shape {
            left: 0%;
            top: 0%;
        }
        .buzon-publico {
            left: 5%;
            top: 5%;
            transform: translate(-5%, -5%);
        }
        .usuario, .usuario-mail-sobre, .usuario-mail, .usuario-sombra, .usuario-brazo, .dialogo {
            img {
                width: 100%;
            }
        }
        .buzon-publico {
            left: 50%;
            transform: translateX(-50%);
            img {
                width: 100%;
            }
        }
        .buzon, .buzon-plantas {
            img {
                width: 45%;
            }
        }
        .bg-shape, .nubes {
            img {
                width: 50%;
            }
        }
    }
    .animation-wrapper {
        .center-animation, .center-bg, .center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }
        .center-bg {
            height: 100%;
            z-index: 1;
            img {
                width: auto;
                height: 100%;
            }
        }
        .center-animation {
            padding: 30px 0;
            height: 100%;
        }
    }
}