/**=====================
   59. Customizer  CSS Start
==========================**/
.customizer-links, .customizer-contain {
  > .nav {
    display: block;
    > .nav-tabs {
      display: block;
    }
  }
}
.customizer-links{
  position: fixed;
  right: 00px;
  top: 50%;
  background-color: $white;
  border-radius: 8px;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 0 15px 3px rgba(176, 185, 189, 0.3);
  transition: all 0.3s ease;
  &.open{
    right: 330px;
    border-radius: 8px 0 0 8px;
    transition: all 0.3s ease;
  }

  .nav-link{
    padding: 0;
    border-radius: 0;
    .settings{
      i{
        color: $primary-color ;
        font-size: 24px;
        padding: 15px 17px;
        display: inline-block;
        cursor: pointer;
        margin: 0;
      }
    }
    &.active{
      .settings {
        i{
          color: $white;
          background-color: $primary-color ;
        }
      }
    }
    &+.nav-link{
      border-top: 1px solid rgba($primary-color , 0.1);
    }
  }
}
.customizer-contain{
  direction: ltr;
  position: fixed;
  height: calc(100vh);
  top: 80px;
  width: 330px;
  right: -330px;
  background-color: $white;
  z-index: 99;
  font-family: $font-nunito;
  transition: all 0.3s ease;
  &.open{
    right: 0px;
    transition: all 0.3s ease;
    box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
  }
  li{
    position: relative;
    &.active{
      &:before{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2;
        left: 0;
        top: 0;
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $dark-color;
        box-shadow: 0 0 11px 5px rgb(226, 226, 226);
      }
    }
  }
  h6{
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: #212529;
  }
  .customizer-header{
    padding:15px 25px;
    border-bottom: 1px solid $light-color;
    .icon-close{
      position: absolute;
      font-size: 18px;
      cursor: pointer;
      font-weight: 900;
      right: 30px;
      color: $primary-color ;
    }
    h5{
      font-weight: 700;
      text-transform: uppercase;
      color: #313131;
    }
    p{
      color: #313131;
    }
  }
  .customizer-body{
    padding : 15px 25px;
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
    .horizontal_sidebar {
      .body {
        ul {
          li {
            &.horizontal-menu {
              width: 100% !important;
              height: 12px !important;
              display: block !important;
            }
          }
          .body {
            width: calc(100% - 0px) !important;
            height: 80% !important;
          }
        }
      }
    }
    .layout-grid{
      &.customizer-mix{
        .color-layout{
          height: 70px;
          width: 80px;
          border: 1px solid #f6f7fb;
          border-radius: 5px;
          display: inline-block;
          margin-bottom: 7px;
          margin-right: 3px;

        }
      }
    }
    .tab-pane{
      > h6{
        position: relative;
        margin-top: 25px;
        padding-top: 25px;
        margin-bottom: 20px;
        border-top: 1px solid #f3f3f3;
        font-size: 15px;
        &:before{
          content: "";
          position: absolute;
          height: 3px;
          width: 14px;
          border-bottom: 2px dotted $dark-color;
          bottom: -4px;
        }
        &:first-child{
          border-top: 0;
          padding-top: 0;
          margin-top: 0;
        }
      }
    }
    .nac-pills{
      border: 1px solid #f1f1f1 !important;
      margin-bottom: 10px;
      border-radius: 5px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      .nav-item{
        width: 33.33%;
        text-align: center;
        .nav-link{
          border-radius: 0;
          &.active{
            background-color: $primary-color ;
            color: $white;
          }
        }
      }
      .nav-link{
        padding: 3.2px 24px;
      }
    }
    li{
      cursor: pointer;
      opacity: 0.9;
      &.active{
        opacity: 1;
      }
    }
    .fade{
      &:not(.show) {
        display: none;
      }
    }

    .color-layout{
      height: 40px;
      width: 40px;
      border-radius: 5px;
      display: inline-block;
      margin-bottom: 7px;
      margin-right: 3px;
      border: 1px solid #b8b8b8;
      padding: 3px;

      > div{
        background-color: #4466f2;
        height: 100%;
        width: 100%;
        border-radius: 3px;
      }
      .body{
        .contain{
          position: absolute;
          height: auto;
          width: 100%;
          li{
            height: 1px;
            width: 65%;
            background-color: #4466f2;
            border-radius: 5px;
            padding: 0;
            margin-left: 10px;
            margin-bottom: 4px;
            &:before{
              content: "";
              position: absolute;
              height: 1px;
              width: 3px;
              background-color: #4466f2;
              left: -6px;
              border-radius: 5px;
            }
          }
        }
      }
      &[data-attr="light-2"],&[data-attr="dark-2"]{
        div{
          background-color: #0288d1;
        }
      }
      &[data-attr="light-3"],&[data-attr="dark-3"]{
        div {
          background-color: #8e24aa;
        }
      }
      &[data-attr="light-4"],&[data-attr="dark-4"]{
        div {
          background-color: #4c2fbf;
        }
      }
      &[data-attr="light-5"],&[data-attr="dark-5"]{
        div {
          background-color: #7c4dff;
        }
      }
      &[data-attr="light-6"],&[data-attr="dark-6"]{
        div {
          background-color: #3949ab;
        }
      }
    }
    [data-attr*="light-"]{
      .body{
        .body{
          ul{
            background-color: #f6f7fb;
          }
        }
      }
    }
    .dark{
      [data-attr*="dark-"]{
        background-color: #2f3c4e;
        .body{
          ul{
            background-color: #2f3c4e;
          }
        }
      }
    }
    .main-layout, .sidebar-type {
      > li{
        height: 90px;
        width: 130px;
        border: 1px solid $light-color;
        border-radius: 5px;
        display: inline-block;
        &+li{
          margin-left: 15px;
          &:last-child {
            margin-top: 10px;
          }
        }
        &:nth-child(3) {
          margin-left: 0;
        }
      }
    }
    .sidebar-setting{
      > li{
        height: 70px;
        width: 87px;
        border: 1px solid $light-color;
        border-radius: 5px;
        display: inline-block;
        &+li{
          margin-left: 7px;
        }
      }
    }
    .sidebar-bg-settings{
      display: flex;
      align-items: center;
      justify-content: space-between;
      li{
        height: 100px;
        width: 100%;
        border: 1px solid $light-color;
        border-radius: 5px;
        display: inline-block;
        margin-right: 9px;
        box-shadow: 0 0 11px -2px rgba(128, 128, 128, 0.40);
      }
    }
  }
  .customizer-footer{
    position: absolute;
    bottom: 0;
    padding: 25px;
    width: 100%;
    border-top: 1px solid $light-color;
    background-color: $white;
  }
}
.layout-grid:not(.customizer-color){

  li{
    padding: 5px;
    > .header{
      height: 9px;
      border-radius: 3px;
      position: relative;
      ul{
        position: absolute;
        top: -8px;
        left: 5px;
        >li{
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 5px;
          top: calc(50% - 3px);
          background-color: $danger-color;
          margin-right: 1px;
          padding: 0;
          &:nth-child(2){
            background-color: $warning-color;
          }
          &:nth-child(3){
            background-color: $success-color;
          }
        }
      }
    }
    > .body{
      background-color: $light-color;
      height: calc(100% - 16px);
      border-radius: 3px;
      position: relative;
      margin-top: 5px;
      text-align: center;
      line-height: 3.1;
      ul{
        height: 100%;
        background-color: $white;
        .sidebar{
          width: 18px;
          height: 100%;
          border-radius: 3px;
          display: inline-block;
          margin-right: 3px;
          padding: 0;
          &.compact{
            width: 12px;
            &~.body {
              width: calc(100% - 19px);
            }
          }
          &.compact-icon{
            width: 7px;
            &~.body {
              width: calc(100% - 14px);
            }
          }
        }
        .body{
          width: calc(100% - 25px);
          padding: 0;
          margin: 0;
          height: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          .badge{
            position: absolute;
          }
        }
      }
    }
  }
}
.full-size {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.bg-color-shape {
  background-color: #ffeee5;
  background-image: url('/static/media/noticias-bg.bfb6f20e.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}
.bg-login-form {
  background-color: #ffffff;
  z-index: 9999999;
}
.login-form {
  .form-group {
    margin-bottom: 2rem;
    &:first-child {
      margin-top: 2rem;
    }
  }
  input {
    background-color: #fff4ef;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    border-left: 8px solid #fc813d;
    border-radius: 0;
    color: #fc813d;
    padding: 10px 5px 10px 10px;
    &::placeholder {
      color: #fc813d;
    }
    &:focus {
      border-color: #fc813d;
    }
  }
}
.dark-bg {
  background-color: #0b1729;
  color: #ffffff;
}
.bg-buzonrojo-ilustracion {
  background-color: #08243e;
}
.blog>.blog-box.blog-list {
  padding: 20px 30px;
}
.blog>.blog-box.blog-list:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.blog>.blog-box.blog-list:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.blog > .blog-box.blog-list:nth-child(3), .blog > .blog-box.blog-list:nth-child(2) {
  background-color: rgba(255, 238, 229, .5);
}
.small-title {
  color: #c1452c;
}
.bg-color {
  background-color: #ffeee5;
}
.blog-list h6 {
  color: #c1452c;
  display: flex;
}
.bg-date {
  background-color: #ffeee6;
}
.blog-list .blog-social {
  display: flex;
}
// .blog-list .blog-social li:first-child {
//   color: #777;
// }
.blog-list .blog-social li+li {
  color: #777;
  padding-left: 10px;
}
.blog-list .blog-social li, .blog-list p {
  color: #777;
}
.title-widget {
  color: #FC813D;
}
.border-after-xs div:first-child {
  border-right: 1px solid #777;
}
.cambio {
  background-image: url('../../images/ilustraciones/home/shape-bg.png');
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: auto 100%;
}
.comunicados {
  background-image: url('../../images/ilustraciones/home/comunicados.png');
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: auto 100%;
}
.notas {
  background-image: url('../../images/ilustraciones/home/notas.png');
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
}
.birthday-card {
  background-image: url('../../images/ilustraciones/home/cumpleanos-sin-nombre.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 15px;
  height: 500px;
}
.marco_cumple {
  background: transparent;
}
.bg-shape-widget {
  background-image: url('../../images/ilustraciones/noticias/noticias-bg.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
*:focus {
  outline: none !important;
}
.custom-card {
  .profile-details {
    h4 {
      text-transform: capitalize;
    }
  }
}
.favorite-btn {
  .icon {
    font-size: 20px;
    color: #cfd4e0;
    &.fa-star {
      color: #fc813d;
    }
  }
}
.like-btn {
  .icon {
    font-size: 20px;
    color:#cfd4e0;
    &.fa-thumbs-up {
      color: #fc813d;
    }
  }
  span {
    color: #777777;
  }
}
.no-shadow {
  box-shadow: none !important;
}
/**=====================
     59.Customizer CSS Ends
==========================**/