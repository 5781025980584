/**=====================
    26. Form CSS Start
==========================**/
form {
  &.needs-validation {
    .form-row {
      input {
        ~  span {
          color: $danger-color;
        }
      }
    }
    .input-group {
      span {
        width: 100%;
      }
    }
    &.tooltip-validation {
      &.validateClass {
        .form-row {
          input {
            ~  span {
              padding: .25rem .5rem;
              margin-top: .1rem;
              font-size: .875rem;
              line-height: 1.5;
              color: $white;
              background-color: rgba($danger-color,.9);
              border-radius: .25rem;
            }
          }
        }
      }
    }
  }
}
.billing-form {
  margin-bottom: -20px;
} 
.height-35{
  height: 35px !important;
}
.theme-form {
  .form-control{
    :focus {
      box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
    }
  }
  &.sm-form{
    .form-group{
      margin-bottom: $sm-form-margin-bottom;
    }
  }
  .form-group {
    margin-bottom: $form-group-margin-bottom;
    textarea{
      border-color: $form-input-border-color;
      &::-webkit-input-placeholder{
        color: $form-placeholder-color;
        font-size: $form-placeholder-font-size;
        letter-spacing: $form-placeholder-letter-spacing;
        font-weight: $form-placeholder-font-weight;
      }
    }
    input[type=text], input[type=email], input[type=search],
    input[type=password], input[type=number], input[type=tel], input[type=date], input[type=datetime-local]
    , input[type=time], input[type=datetime-local], input[type=month],input[type=week],
    input[type=url],input[type=file], select {
      border-color: $form-input-border-color;
      background-color: $form-input-bg-color;
      font-size: $form-placeholder-font-size;
      color: $form-placeholder-color;
      font-family: $font-work-sans, $font-serif;
      &::-webkit-input-placeholder{
        color: $form-placeholder-color;
        font-size: $form-placeholder-font-size;
        letter-spacing: $form-placeholder-letter-spacing;
        font-weight: $form-placeholder-font-weight;
      }
    }
    select.form-control:not([size]):not([multiple]){
      border-color: $form-input-border-color;
      font-size: $form-placeholder-font-size;
    }
  }
  .checkbox {
    label {
      padding-left: 10px;
    }
  }
  .form-divider {
    position: relative;
    clear: both;
    height: 0;
    margin: 30px 0;
    border-top: 1px solid $light-semi-gray;
    &:before {
      position: absolute;
      top: -11px;
      left: 40%;
      margin-left: -10px;
      padding: 0 5px;
      content: "Or Signup With";
      color: $theme-font-color;
      background: $white;
    }
  }
  .login-divider {
    position: relative;
    clear: both;
    height: 0;
    margin: 30px 0;
    border-top: 1px solid $light-semi-gray;
    &:before {
      position: absolute;
      top: -11px;
      left: 40%;
      margin-left: -10px;
      padding: 0 5px;
      color: $theme-font-color;
      background: $white;
      content: "Or Login With";
    }
  }
  .social {
    .form-group {
      justify-content: center;
      .btn {
        padding: 6px 15px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .btn-fb {
    background-color: $fb;
    color: $white;
  }
  .btn-twitter {
    background-color: $twitter;
    color: $white;
  }
  .btn-google {
    background-color: $google-plus;
    color: $white;
  }
  .btn-github {
    background-color: $github;
    color: $white;
  }
}
.form-inline{
  .form-group{
    margin-right: 15px;
    .col-form-label
    {
      margin-right: 5px;
    }
  }
}
.search-form{
  .form-group{
    &:before{
      position: absolute;
      content: "";
      width: 1px;
      height: 25px;
      background: #e8ebf2;
      left: 82px;
      top: 39px;
    }
    &:after{
      position: absolute;
      content: "\f002";
      font-family: FontAwesome;
      top: 41px;
      left: 53px;
      color: #8e8e8e;
    }
  }
  input{
    border: 1px solid $light-semi-gray;
    padding: 10px 10px 10px 70px;
    border-radius: 50px;
    background-color: $light-color;
  }
}
.form-label-align-right{
  label{
    text-align:right;
    padding-top: 17px;
    font-weight: 500;
  }
}
.custom-file-label{
  line-height: 1.8;
  &::after{
    line-height: 1.8;
  }
}

.checkbox:checked ~ .background-color{
	background-color: $white;
}


[type="checkbox"]:checked,
[type="checkbox"]:not(:checked),
[type="radio"]:checked,
[type="radio"]:not(:checked){
	position: absolute;
	left: -9999px;
	width: 0;
	height: 0;
	visibility: hidden;
}
.checkbox:checked + label,
.checkbox:not(:checked) + label{
	position: relative;
	width: 70px;
	display: inline-block;
	padding: 0;
	margin: 0 auto;
	text-align: center;
	margin: 17px 0;
	margin-top: 100px;
	height: 6px;
	border-radius: 4px;
	background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
	z-index: 100 !important;
}
.checkbox:checked + label:before,
.checkbox:not(:checked) + label:before {
	position: absolute;
	font-family: 'unicons';
	cursor: pointer;
	top: -17px;
	z-index: 2;
	font-size: 20px;
	line-height: 40px;
	text-align: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}
.checkbox:not(:checked) + label:before {
	content: '\eac1';
	left: 0;
	color: #ecedf3;
	background-color: #353746;
	box-shadow: 0 4px 4px rgba(0,0,0,0.15), 0 0 0 1px rgba(26,53,71,0.07);
}
.checkbox:checked + label:before {
	content: '\eb8f';
	left: 30px;
	color: #f8ab37;
	background-color: #1f2029;
	box-shadow: 0 4px 4px rgba(26,53,71,0.25), 0 0 0 1px rgba(26,53,71,0.07);
}

.checkbox:checked ~ .section .container .row .col-12 p{
	color: #1f2029;
}


.checkbox-tools:checked + label,
.checkbox-tools:not(:checked) + label{
	position: relative;
	display: inline-block;
	padding: 20px 10px;
	width: 100%;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 1px;
	margin: 0 auto;
	margin-left: 5px;
	margin-right: 5px;
	margin-bottom: 10px;
	text-align: center;
	border-radius: 4px;
	overflow: hidden;
	cursor: pointer;
	text-transform: uppercase;
	color: $white;
	-webkit-transition: all 300ms linear;
  transition: all 300ms linear; 
  img {
    display: block;
    margin: 0 auto 20px;
  }
}
.checkbox-tools:not(:checked) + label{
  border: 2px solid #353746;
  background-color: #0b1729;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.checkbox-tools:checked + label{
  background-color: transparent;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.checkbox-tools:not(:checked) + label:hover{
  background-color: #353746;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.checkbox-tools:checked + label::before,
.checkbox-tools:not(:checked) + label::before{
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  border-radius: 4px;
  border: 2px solid #e22d2d;
	background-color: #e22d2d;
	z-index: -1;
}
.checkbox-tools:checked + label .uil,
.checkbox-tools:not(:checked) + label .uil{
	font-size: 24px;
	line-height: 24px;
	display: block;
	padding-bottom: 10px;
}

.checkbox:checked ~ .section .container .row .col-12 .checkbox-tools:not(:checked) + label{
	background-color: #f0eff3;
	color: #1f2029;
	box-shadow: 0 1x 4px 0 rgba(0, 0, 0, 0.05);
}

.checkbox:checked ~ .section .container .row .col-12 .checkbox-booking:not(:checked) + label,
.checkbox:checked ~ .section .container .row .col-12 .checkbox-booking:checked + label{
	background-color: #f0eff3;
    color: #1f2029;
}
.checkbox:checked ~ .section .container .row .col-12 .checkbox-booking:checked + label::after,
.checkbox:checked ~ .section .container .row .col-12 .checkbox-booking:not(:checked) + label::after{
	background-color: #f0eff3;
}
/**=====================
    26. Form CSS Ends
==========================**/
