.slick-prev {
    padding-right: 50px;
    margin-right: -40px;
    z-index: 2;
    /* background: black; */
}
.slick-prev:before {
    color: #C0C0C0;
    margin-right: -75px;
    /* background: black; */
}

.slick-next:before {
    color: #C0C0C0;
    margin-left: -60px;
}