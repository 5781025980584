/* VARIABLES */
/* Applies a stroke to the icons. Can use percentage for responsive */
.climacon_component-stroke {
  fill: white;
  stroke-width: 0%;
  stroke: black;
}

.climacon_component-fill {
  fill: #ffffff;
  stroke-width: 0%;
  stroke: black;
}

/* SVG GLOBAL */
svg.climacon {
  display: inline-block;
  width: 80px;
  height: 80px;
  shape-rendering: geometricPrecision;
}

g, path, circle, rect {
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-animation-duration: 18s;
          animation-duration: 18s;
  -webkit-animation-direction: normal;
          animation-direction: normal;
}

/* SUN */
.climacon_componentWrap-sun {
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-transform-origin: 50px 50px;
          transform-origin: 50px 50px;
}

.climacon_componentWrap_sunSpoke .climacon_component-stroke_sunSpoke {
  -webkit-animation-name: scale;
          animation-name: scale;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-duration: 4.5s;
          animation-duration: 4.5s;
}

.climacon_componentWrap_sunSpoke .climacon_component-stroke_sunSpoke:nth-child(even) {
  -webkit-animation-delay: 4.5s;
          animation-delay: 4.5s;
}

/* MOON */
.climacon_componentWrap-moon {
  -webkit-animation-name: partialRotate;
          animation-name: partialRotate;
  -webkit-animation-duration: 18s;
          animation-duration: 18s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

/* WIND */
.climacon_componentWrap-wind {
  -webkit-animation-name: translateWind;
          animation-name: translateWind;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

/* SNOWFLAKE */
.climacon_componentWrap-snowflake {
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-duration: 54s;
          animation-duration: 54s;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-transform-origin: 50px 50px;
          transform-origin: 50px 50px;
}

/* CLOUD SUN */
.climacon_componentWrap-sun_cloud {
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-iteration-count: 1, infinite;
          animation-iteration-count: 1, infinite;
  -webkit-animation-timing-function: ease-out, linear;
          animation-timing-function: ease-out, linear;
  -webkit-animation-delay: 0, 4.5s;
          animation-delay: 0, 4.5s;
  -webkit-animation-duration: 4.5s, 18s;
          animation-duration: 4.5s, 18s;
}

.climacon_componentWrap-sun_cloud .climacon_componentWrap_sunSpoke .climacon_component-stroke_sunSpoke {
  fill-opacity: 0;
  -webkit-animation-name: fillOpacity, scale;
          animation-name: fillOpacity, scale;
  -webkit-transform-origin: 50px 50px;
          transform-origin: 50px 50px;
  -webkit-animation-iteration-count: 1, infinite;
          animation-iteration-count: 1, infinite;
  -webkit-animation-delay: 4.5s, 0;
          animation-delay: 4.5s, 0;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.climacon_componentWrap-sun_cloud .climacon_componentWrap_sunSpoke .climacon_component-stroke_sunSpoke:nth-child(even) {
  -webkit-animation-delay: 4.5s;
          animation-delay: 4.5s;
}

/* CLOUD MOON */
.climacon_componentWrap-moon_cloud {
  -webkit-animation-name: behindCloudMove, partialRotate;
          animation-name: behindCloudMove, partialRotate;
  -webkit-animation-iteration-count: 1, infinite;
          animation-iteration-count: 1, infinite;
  -webkit-animation-timing-function: ease-out, linear;
          animation-timing-function: ease-out, linear;
  -webkit-animation-delay: 0, 4.5s;
          animation-delay: 0, 4.5s;
  -webkit-animation-duration: 4.5s, 18s;
          animation-duration: 4.5s, 18s;
}

/* DRIZZLE */
.climacon_component-stroke_drizzle {
  fill-opacity: 0;
  -webkit-animation-name: drizzleFall, fillOpacity2;
          animation-name: drizzleFall, fillOpacity2;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
}

.climacon_component-stroke_drizzle:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.climacon_component-stroke_drizzle:nth-child(2) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

.climacon_component-stroke_drizzle:nth-child(3) {
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s;
}

/* RAIN */
.climacon_component-stroke_rain {
  fill-opacity: 0;
  -webkit-animation-name: rainFall, fillOpacity2;
          animation-name: rainFall, fillOpacity2;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
}

.climacon_component-stroke_rain:nth-child(n+4) {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

.climacon_component-stroke_rain_alt:nth-child(2) {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

/* HAIL */
/* HAIL ALT */
.climacon_component-stroke_hailAlt {
  fill-opacity: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.climacon_component-stroke_hailAlt-left {
  -webkit-animation-name: hailLeft, fillOpacity2;
          animation-name: hailLeft, fillOpacity2;
}

.climacon_component-stroke_hailAlt-middle {
  -webkit-animation-name: hailMiddle, fillOpacity2;
          animation-name: hailMiddle, fillOpacity2;
}

.climacon_component-stroke_hailAlt-middle:nth-child(2) {
  -webkit-animation-name: hailMiddle2, fillOpacity2;
          animation-name: hailMiddle2, fillOpacity2;
}

.climacon_component-stroke_hailAlt-right {
  -webkit-animation-name: hailRight, fillOpacity2;
          animation-name: hailRight, fillOpacity2;
}

.climacon_component-stroke_hailAlt:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.climacon_component-stroke_hailAlt:nth-child(2) {
  -webkit-animation-delay: 0.16667s;
          animation-delay: 0.16667s;
}

.climacon_component-stroke_hailAlt:nth-child(3) {
  -webkit-animation-delay: 0.33333s;
          animation-delay: 0.33333s;
}

.climacon_component-stroke_hailAlt:nth-child(4) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.climacon_component-stroke_hailAlt:nth-child(5) {
  -webkit-animation-delay: 0.66667s;
          animation-delay: 0.66667s;
}

.climacon_component-stroke_hailAlt:nth-child(6) {
  -webkit-animation-delay: 0.83333s;
          animation-delay: 0.83333s;
}

/* SNOW */
.climacon_component-stroke_snow {
  fill-opacity: 0;
  -webkit-animation-name: snowFall, fillOpacity2;
          animation-name: snowFall, fillOpacity2;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-duration: 9s;
          animation-duration: 9s;
}

.climacon_component-stroke_snow:nth-child(3) {
  -webkit-animation-name: snowFall2, fillOpacity2;
          animation-name: snowFall2, fillOpacity2;
}

.climacon_component-stroke_snow:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.climacon_component-stroke_snow:nth-child(2) {
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

.climacon_component-stroke_snow:nth-child(3) {
  -webkit-animation-delay: 6s;
          animation-delay: 6s;
}

/* SNOW ALT */
.climacon_wrapperComponent-snowAlt {
  fill-opacity: 0;
  -webkit-animation-name: snowFall, fillOpacity2;
          animation-name: snowFall, fillOpacity2;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-duration: 9s;
          animation-duration: 9s;
}

/* FOG */
.climacon_component-stroke_fogLine {
  fill-opacity: 0.5;
  -webkit-animation-name: translateFog, fillOpacityFog;
          animation-name: translateFog, fillOpacityFog;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: 18s;
          animation-duration: 18s;
}

.climacon_component-stroke_fogLine:nth-child(even) {
  -webkit-animation-delay: 9s;
          animation-delay: 9s;
}

/* LIGHTNING */
.climacon_component-stroke_lightning {
  fill-opacity: 0;
  -webkit-animation-name: fillOpacityLightning;
          animation-name: fillOpacityLightning;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 18s;
          animation-duration: 18s;
}

/* TORNADO */
.climacon_component-stroke_tornadoLine {
  -webkit-animation-name: translateTornado1;
          animation-name: translateTornado1;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-duration: 18s;
          animation-duration: 18s;
}

.climacon_component-stroke_tornadoLine:nth-child(1) {
  -webkit-animation-name: translateTornado1;
          animation-name: translateTornado1;
}

.climacon_component-stroke_tornadoLine:nth-child(2) {
  -webkit-animation-name: translateTornado2;
          animation-name: translateTornado2;
}

.climacon_component-stroke_tornadoLine:nth-child(3) {
  -webkit-animation-name: translateTornado3;
          animation-name: translateTornado3;
}

.climacon_component-stroke_tornadoLine:nth-child(4) {
  -webkit-animation-name: translateTornado4;
          animation-name: translateTornado4;
}

.climacon_component-stroke_tornadoLine:nth-child(5) {
  -webkit-animation-name: translateTornado5;
          animation-name: translateTornado5;
}

.climacon_component-stroke_tornadoLine:nth-child(6) {
  -webkit-animation-name: translateTornado6;
          animation-name: translateTornado6;
}

.climacon_componentWrap-sunsetAlt {
  -webkit-animation-name: translateSunset;
          animation-name: translateSunset;
  -webkit-animation-duration: 4.5s;
          animation-duration: 4.5s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}

.climacon_componentWrap-sunsetAlt {
  -webkit-animation-name: translateSunset;
          animation-name: translateSunset;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

.climacon_iconWrap-sun .climacon_component-stroke_sunSpoke, .climacon_iconWrap-sunFill .climacon_component-stroke_sunSpoke {
  fill-opacity: 1;
  -webkit-animation-name: scale;
          animation-name: scale;
  -webkit-transform-origin: 50px 50px;
          transform-origin: 50px 50px;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-delay: 0;
          animation-delay: 0;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

.climacon_iconWrap-sun .climacon_component-stroke_sunSpoke:nth-child(even), .climacon_iconWrap-sunFill .climacon_component-stroke_sunSpoke:nth-child(even) {
  -webkit-animation-delay: 4.5s;
          animation-delay: 4.5s;
}

.climacon-iconWrap_sunFill .climacon_component-stroke_sunSpoke {
  fill-opacity: 1;
  -webkit-animation-name: scale;
          animation-name: scale;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-delay: 0;
          animation-delay: 0;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

.climacon-iconWrap_sunFill .climacon_component-stroke_sunSpoke:nth-child(even) {
  -webkit-animation-delay: 4.5s;
          animation-delay: 4.5s;
}

.climacon_component-stroke_arrow-up {
  fill-opacity: 0;
  -webkit-animation-name: fillOpacity2, translateArrowUp;
          animation-name: fillOpacity2, translateArrowUp;
  -webkit-animation-duration: 4.5s;
          animation-duration: 4.5s;
}

.climacon_component-stroke_arrow-down {
  fill-opacity: 0;
  -webkit-animation-name: fillOpacity2, translateArrowDown;
          animation-name: fillOpacity2, translateArrowDown;
  -webkit-animation-duration: 4.5s;
          animation-duration: 4.5s;
}

.climacon_componentWrap-sunrise .climacon_component-stroke_sunSpoke, .climacon_componentWrap-sunset .climacon_component-stroke_sunSpoke {
  -webkit-animation-name: scale;
          animation-name: scale;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 4.5s;
          animation-duration: 4.5s;
  -webkit-animation-delay: 0;
          animation-delay: 0;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.climacon_componentWrap-sunrise .climacon_component-stroke_sunSpoke:nth-child(even), .climacon_componentWrap-sunset .climacon_component-stroke_sunSpoke:nth-child(even) {
  -webkit-animation-delay: 4.5s;
          animation-delay: 4.5s;
}

.climacon_componentWrap-sunriseAlt {
  -webkit-animation-name: translateSunrise, fillOpacity;
          animation-name: translateSunrise, fillOpacity;
  -webkit-animation-duration: 18s, 9s;
          animation-duration: 18s, 9s;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.climacon_componentWrap-sunriseAlt .climacon_component-stroke_sunSpoke {
  fill-opacity: 0;
  -webkit-animation-name: fillOpacity, scale;
          animation-name: fillOpacity, scale;
  -webkit-animation-direction: normal, alternate;
          animation-direction: normal, alternate;
  -webkit-animation-iteration-count: 1, infinite;
          animation-iteration-count: 1, infinite;
  -webkit-animation-duration: 4.5s;
          animation-duration: 4.5s;
  -webkit-animation-delay: 4.5s, 0;
          animation-delay: 4.5s, 0;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.climacon_componentWrap-sunriseAlt .climacon_component-stroke_sunSpoke:nth-child(even) {
  -webkit-animation-delay: 4.5s, 4.5s;
          animation-delay: 4.5s, 4.5s;
}

.climacon_componentWrap-sunsetAlt {
  -webkit-animation-name: translateSunset;
          animation-name: translateSunset;
  -webkit-animation-delay: 0;
          animation-delay: 0;
  -webkit-animation-duration: 18s;
          animation-duration: 18s;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

/* ANIMATIONS */
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes partialRotate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  25% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
  50% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  75% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes partialRotate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  25% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
  50% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  75% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(0.5, 0.5);
            transform: scale(0.5, 0.5);
  }
}
@keyframes scale {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(0.5, 0.5);
            transform: scale(0.5, 0.5);
  }
}
@-webkit-keyframes behindCloudMove {
  0% {
    -webkit-transform: translateX(-1.75px) translateY(1.75px);
            transform: translateX(-1.75px) translateY(1.75px);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
  }
}
@keyframes behindCloudMove {
  0% {
    -webkit-transform: translateX(-1.75px) translateY(1.75px);
            transform: translateX(-1.75px) translateY(1.75px);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
  }
}
@-webkit-keyframes drizzleFall {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(21px);
            transform: translateY(21px);
  }
}
@keyframes drizzleFall {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(21px);
            transform: translateY(21px);
  }
}
@-webkit-keyframes rainFall {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(14px);
            transform: translateY(14px);
  }
}
@keyframes rainFall {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(14px);
            transform: translateY(14px);
  }
}
@-webkit-keyframes rainFall2 {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(14px);
            transform: translateY(14px);
  }
  100% {
    -webkit-transform: translateX(14px) translateY(14px);
            transform: translateX(14px) translateY(14px);
  }
}
@keyframes rainFall2 {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(14px);
            transform: translateY(14px);
  }
  100% {
    -webkit-transform: translateX(14px) translateY(14px);
            transform: translateX(14px) translateY(14px);
  }
}
@-webkit-keyframes hailLeft {
  50% {
    -webkit-transform: translateY(17.5px);
            transform: translateY(17.5px);
  }
  51% {
    -webkit-transform: translateY(17.5px) translateX(-0.3px);
            transform: translateY(17.5px) translateX(-0.3px);
  }
  53% {
    -webkit-transform: translateY(16.40333px) translateX(-0.9px);
            transform: translateY(16.40333px) translateX(-0.9px);
  }
  55% {
    -webkit-transform: translateY(15.32396px) translateX(-1.5px);
            transform: translateY(15.32396px) translateX(-1.5px);
  }
  57% {
    -webkit-transform: translateY(14.27891px) translateX(-2.1px);
            transform: translateY(14.27891px) translateX(-2.1px);
  }
  59% {
    -webkit-transform: translateY(13.28466px) translateX(-2.7px);
            transform: translateY(13.28466px) translateX(-2.7px);
  }
  61% {
    -webkit-transform: translateY(12.35688px) translateX(-3.3px);
            transform: translateY(12.35688px) translateX(-3.3px);
  }
  63% {
    -webkit-transform: translateY(11.51021px) translateX(-3.9px);
            transform: translateY(11.51021px) translateX(-3.9px);
  }
  65% {
    -webkit-transform: translateY(10.75801px) translateX(-4.5px);
            transform: translateY(10.75801px) translateX(-4.5px);
  }
  67% {
    -webkit-transform: translateY(10.11213px) translateX(-5.1px);
            transform: translateY(10.11213px) translateX(-5.1px);
  }
  69% {
    -webkit-transform: translateY(9.58276px) translateX(-5.7px);
            transform: translateY(9.58276px) translateX(-5.7px);
  }
  71% {
    -webkit-transform: translateY(9.17826px) translateX(-6.3px);
            transform: translateY(9.17826px) translateX(-6.3px);
  }
  73% {
    -webkit-transform: translateY(8.90499px) translateX(-6.9px);
            transform: translateY(8.90499px) translateX(-6.9px);
  }
  75% {
    -webkit-transform: translateY(8.76727px) translateX(-7.5px);
            transform: translateY(8.76727px) translateX(-7.5px);
  }
  77% {
    -webkit-transform: translateY(8.76727px) translateX(-8.1px);
            transform: translateY(8.76727px) translateX(-8.1px);
  }
  79% {
    -webkit-transform: translateY(8.90499px) translateX(-8.7px);
            transform: translateY(8.90499px) translateX(-8.7px);
  }
  81% {
    -webkit-transform: translateY(9.17826px) translateX(-9.3px);
            transform: translateY(9.17826px) translateX(-9.3px);
  }
  83% {
    -webkit-transform: translateY(9.58276px) translateX(-9.9px);
            transform: translateY(9.58276px) translateX(-9.9px);
  }
  85% {
    -webkit-transform: translateY(10.11213px) translateX(-10.5px);
            transform: translateY(10.11213px) translateX(-10.5px);
  }
  87% {
    -webkit-transform: translateY(10.75801px) translateX(-11.1px);
            transform: translateY(10.75801px) translateX(-11.1px);
  }
  89% {
    -webkit-transform: translateY(11.51021px) translateX(-11.7px);
            transform: translateY(11.51021px) translateX(-11.7px);
  }
  91% {
    -webkit-transform: translateY(12.35688px) translateX(-12.3px);
            transform: translateY(12.35688px) translateX(-12.3px);
  }
  93% {
    -webkit-transform: translateY(13.28466px) translateX(-12.9px);
            transform: translateY(13.28466px) translateX(-12.9px);
  }
  95% {
    -webkit-transform: translateY(14.27891px) translateX(-13.5px);
            transform: translateY(14.27891px) translateX(-13.5px);
  }
  97% {
    -webkit-transform: translateY(15.32396px) translateX(-14.1px);
            transform: translateY(15.32396px) translateX(-14.1px);
  }
  99% {
    -webkit-transform: translateY(16.40333px) translateX(-14.7px);
            transform: translateY(16.40333px) translateX(-14.7px);
  }
}
@keyframes hailLeft {
  50% {
    -webkit-transform: translateY(17.5px);
            transform: translateY(17.5px);
  }
  51% {
    -webkit-transform: translateY(17.5px) translateX(-0.3px);
            transform: translateY(17.5px) translateX(-0.3px);
  }
  53% {
    -webkit-transform: translateY(16.40333px) translateX(-0.9px);
            transform: translateY(16.40333px) translateX(-0.9px);
  }
  55% {
    -webkit-transform: translateY(15.32396px) translateX(-1.5px);
            transform: translateY(15.32396px) translateX(-1.5px);
  }
  57% {
    -webkit-transform: translateY(14.27891px) translateX(-2.1px);
            transform: translateY(14.27891px) translateX(-2.1px);
  }
  59% {
    -webkit-transform: translateY(13.28466px) translateX(-2.7px);
            transform: translateY(13.28466px) translateX(-2.7px);
  }
  61% {
    -webkit-transform: translateY(12.35688px) translateX(-3.3px);
            transform: translateY(12.35688px) translateX(-3.3px);
  }
  63% {
    -webkit-transform: translateY(11.51021px) translateX(-3.9px);
            transform: translateY(11.51021px) translateX(-3.9px);
  }
  65% {
    -webkit-transform: translateY(10.75801px) translateX(-4.5px);
            transform: translateY(10.75801px) translateX(-4.5px);
  }
  67% {
    -webkit-transform: translateY(10.11213px) translateX(-5.1px);
            transform: translateY(10.11213px) translateX(-5.1px);
  }
  69% {
    -webkit-transform: translateY(9.58276px) translateX(-5.7px);
            transform: translateY(9.58276px) translateX(-5.7px);
  }
  71% {
    -webkit-transform: translateY(9.17826px) translateX(-6.3px);
            transform: translateY(9.17826px) translateX(-6.3px);
  }
  73% {
    -webkit-transform: translateY(8.90499px) translateX(-6.9px);
            transform: translateY(8.90499px) translateX(-6.9px);
  }
  75% {
    -webkit-transform: translateY(8.76727px) translateX(-7.5px);
            transform: translateY(8.76727px) translateX(-7.5px);
  }
  77% {
    -webkit-transform: translateY(8.76727px) translateX(-8.1px);
            transform: translateY(8.76727px) translateX(-8.1px);
  }
  79% {
    -webkit-transform: translateY(8.90499px) translateX(-8.7px);
            transform: translateY(8.90499px) translateX(-8.7px);
  }
  81% {
    -webkit-transform: translateY(9.17826px) translateX(-9.3px);
            transform: translateY(9.17826px) translateX(-9.3px);
  }
  83% {
    -webkit-transform: translateY(9.58276px) translateX(-9.9px);
            transform: translateY(9.58276px) translateX(-9.9px);
  }
  85% {
    -webkit-transform: translateY(10.11213px) translateX(-10.5px);
            transform: translateY(10.11213px) translateX(-10.5px);
  }
  87% {
    -webkit-transform: translateY(10.75801px) translateX(-11.1px);
            transform: translateY(10.75801px) translateX(-11.1px);
  }
  89% {
    -webkit-transform: translateY(11.51021px) translateX(-11.7px);
            transform: translateY(11.51021px) translateX(-11.7px);
  }
  91% {
    -webkit-transform: translateY(12.35688px) translateX(-12.3px);
            transform: translateY(12.35688px) translateX(-12.3px);
  }
  93% {
    -webkit-transform: translateY(13.28466px) translateX(-12.9px);
            transform: translateY(13.28466px) translateX(-12.9px);
  }
  95% {
    -webkit-transform: translateY(14.27891px) translateX(-13.5px);
            transform: translateY(14.27891px) translateX(-13.5px);
  }
  97% {
    -webkit-transform: translateY(15.32396px) translateX(-14.1px);
            transform: translateY(15.32396px) translateX(-14.1px);
  }
  99% {
    -webkit-transform: translateY(16.40333px) translateX(-14.7px);
            transform: translateY(16.40333px) translateX(-14.7px);
  }
}
@-webkit-keyframes hailMiddle {
  50% {
    -webkit-transform: translateY(17.5px);
            transform: translateY(17.5px);
  }
  51% {
    -webkit-transform: translateY(17.5px) translateX(-0.15px);
            transform: translateY(17.5px) translateX(-0.15px);
  }
  53% {
    -webkit-transform: translateY(16.40333px) translateX(-0.45px);
            transform: translateY(16.40333px) translateX(-0.45px);
  }
  55% {
    -webkit-transform: translateY(15.32396px) translateX(-0.75px);
            transform: translateY(15.32396px) translateX(-0.75px);
  }
  57% {
    -webkit-transform: translateY(14.27891px) translateX(-1.05px);
            transform: translateY(14.27891px) translateX(-1.05px);
  }
  59% {
    -webkit-transform: translateY(13.28466px) translateX(-1.35px);
            transform: translateY(13.28466px) translateX(-1.35px);
  }
  61% {
    -webkit-transform: translateY(12.35688px) translateX(-1.65px);
            transform: translateY(12.35688px) translateX(-1.65px);
  }
  63% {
    -webkit-transform: translateY(11.51021px) translateX(-1.95px);
            transform: translateY(11.51021px) translateX(-1.95px);
  }
  65% {
    -webkit-transform: translateY(10.75801px) translateX(-2.25px);
            transform: translateY(10.75801px) translateX(-2.25px);
  }
  67% {
    -webkit-transform: translateY(10.11213px) translateX(-2.55px);
            transform: translateY(10.11213px) translateX(-2.55px);
  }
  69% {
    -webkit-transform: translateY(9.58276px) translateX(-2.85px);
            transform: translateY(9.58276px) translateX(-2.85px);
  }
  71% {
    -webkit-transform: translateY(9.17826px) translateX(-3.15px);
            transform: translateY(9.17826px) translateX(-3.15px);
  }
  73% {
    -webkit-transform: translateY(8.90499px) translateX(-3.45px);
            transform: translateY(8.90499px) translateX(-3.45px);
  }
  75% {
    -webkit-transform: translateY(8.76727px) translateX(-3.75px);
            transform: translateY(8.76727px) translateX(-3.75px);
  }
  77% {
    -webkit-transform: translateY(8.76727px) translateX(-4.05px);
            transform: translateY(8.76727px) translateX(-4.05px);
  }
  79% {
    -webkit-transform: translateY(8.90499px) translateX(-4.35px);
            transform: translateY(8.90499px) translateX(-4.35px);
  }
  81% {
    -webkit-transform: translateY(9.17826px) translateX(-4.65px);
            transform: translateY(9.17826px) translateX(-4.65px);
  }
  83% {
    -webkit-transform: translateY(9.58276px) translateX(-4.95px);
            transform: translateY(9.58276px) translateX(-4.95px);
  }
  85% {
    -webkit-transform: translateY(10.11213px) translateX(-5.25px);
            transform: translateY(10.11213px) translateX(-5.25px);
  }
  87% {
    -webkit-transform: translateY(10.75801px) translateX(-5.55px);
            transform: translateY(10.75801px) translateX(-5.55px);
  }
  89% {
    -webkit-transform: translateY(11.51021px) translateX(-5.85px);
            transform: translateY(11.51021px) translateX(-5.85px);
  }
  91% {
    -webkit-transform: translateY(12.35688px) translateX(-6.15px);
            transform: translateY(12.35688px) translateX(-6.15px);
  }
  93% {
    -webkit-transform: translateY(13.28466px) translateX(-6.45px);
            transform: translateY(13.28466px) translateX(-6.45px);
  }
  95% {
    -webkit-transform: translateY(14.27891px) translateX(-6.75px);
            transform: translateY(14.27891px) translateX(-6.75px);
  }
  97% {
    -webkit-transform: translateY(15.32396px) translateX(-7.05px);
            transform: translateY(15.32396px) translateX(-7.05px);
  }
  99% {
    -webkit-transform: translateY(16.40333px) translateX(-7.35px);
            transform: translateY(16.40333px) translateX(-7.35px);
  }
}
@keyframes hailMiddle {
  50% {
    -webkit-transform: translateY(17.5px);
            transform: translateY(17.5px);
  }
  51% {
    -webkit-transform: translateY(17.5px) translateX(-0.15px);
            transform: translateY(17.5px) translateX(-0.15px);
  }
  53% {
    -webkit-transform: translateY(16.40333px) translateX(-0.45px);
            transform: translateY(16.40333px) translateX(-0.45px);
  }
  55% {
    -webkit-transform: translateY(15.32396px) translateX(-0.75px);
            transform: translateY(15.32396px) translateX(-0.75px);
  }
  57% {
    -webkit-transform: translateY(14.27891px) translateX(-1.05px);
            transform: translateY(14.27891px) translateX(-1.05px);
  }
  59% {
    -webkit-transform: translateY(13.28466px) translateX(-1.35px);
            transform: translateY(13.28466px) translateX(-1.35px);
  }
  61% {
    -webkit-transform: translateY(12.35688px) translateX(-1.65px);
            transform: translateY(12.35688px) translateX(-1.65px);
  }
  63% {
    -webkit-transform: translateY(11.51021px) translateX(-1.95px);
            transform: translateY(11.51021px) translateX(-1.95px);
  }
  65% {
    -webkit-transform: translateY(10.75801px) translateX(-2.25px);
            transform: translateY(10.75801px) translateX(-2.25px);
  }
  67% {
    -webkit-transform: translateY(10.11213px) translateX(-2.55px);
            transform: translateY(10.11213px) translateX(-2.55px);
  }
  69% {
    -webkit-transform: translateY(9.58276px) translateX(-2.85px);
            transform: translateY(9.58276px) translateX(-2.85px);
  }
  71% {
    -webkit-transform: translateY(9.17826px) translateX(-3.15px);
            transform: translateY(9.17826px) translateX(-3.15px);
  }
  73% {
    -webkit-transform: translateY(8.90499px) translateX(-3.45px);
            transform: translateY(8.90499px) translateX(-3.45px);
  }
  75% {
    -webkit-transform: translateY(8.76727px) translateX(-3.75px);
            transform: translateY(8.76727px) translateX(-3.75px);
  }
  77% {
    -webkit-transform: translateY(8.76727px) translateX(-4.05px);
            transform: translateY(8.76727px) translateX(-4.05px);
  }
  79% {
    -webkit-transform: translateY(8.90499px) translateX(-4.35px);
            transform: translateY(8.90499px) translateX(-4.35px);
  }
  81% {
    -webkit-transform: translateY(9.17826px) translateX(-4.65px);
            transform: translateY(9.17826px) translateX(-4.65px);
  }
  83% {
    -webkit-transform: translateY(9.58276px) translateX(-4.95px);
            transform: translateY(9.58276px) translateX(-4.95px);
  }
  85% {
    -webkit-transform: translateY(10.11213px) translateX(-5.25px);
            transform: translateY(10.11213px) translateX(-5.25px);
  }
  87% {
    -webkit-transform: translateY(10.75801px) translateX(-5.55px);
            transform: translateY(10.75801px) translateX(-5.55px);
  }
  89% {
    -webkit-transform: translateY(11.51021px) translateX(-5.85px);
            transform: translateY(11.51021px) translateX(-5.85px);
  }
  91% {
    -webkit-transform: translateY(12.35688px) translateX(-6.15px);
            transform: translateY(12.35688px) translateX(-6.15px);
  }
  93% {
    -webkit-transform: translateY(13.28466px) translateX(-6.45px);
            transform: translateY(13.28466px) translateX(-6.45px);
  }
  95% {
    -webkit-transform: translateY(14.27891px) translateX(-6.75px);
            transform: translateY(14.27891px) translateX(-6.75px);
  }
  97% {
    -webkit-transform: translateY(15.32396px) translateX(-7.05px);
            transform: translateY(15.32396px) translateX(-7.05px);
  }
  99% {
    -webkit-transform: translateY(16.40333px) translateX(-7.35px);
            transform: translateY(16.40333px) translateX(-7.35px);
  }
}
@-webkit-keyframes hailMiddle2 {
  50% {
    -webkit-transform: translateY(17.5px);
            transform: translateY(17.5px);
  }
  51% {
    -webkit-transform: translateY(17.5px) translateX(0.15px);
            transform: translateY(17.5px) translateX(0.15px);
  }
  53% {
    -webkit-transform: translateY(16.40333px) translateX(0.45px);
            transform: translateY(16.40333px) translateX(0.45px);
  }
  55% {
    -webkit-transform: translateY(15.32396px) translateX(0.75px);
            transform: translateY(15.32396px) translateX(0.75px);
  }
  57% {
    -webkit-transform: translateY(14.27891px) translateX(1.05px);
            transform: translateY(14.27891px) translateX(1.05px);
  }
  59% {
    -webkit-transform: translateY(13.28466px) translateX(1.35px);
            transform: translateY(13.28466px) translateX(1.35px);
  }
  61% {
    -webkit-transform: translateY(12.35688px) translateX(1.65px);
            transform: translateY(12.35688px) translateX(1.65px);
  }
  63% {
    -webkit-transform: translateY(11.51021px) translateX(1.95px);
            transform: translateY(11.51021px) translateX(1.95px);
  }
  65% {
    -webkit-transform: translateY(10.75801px) translateX(2.25px);
            transform: translateY(10.75801px) translateX(2.25px);
  }
  67% {
    -webkit-transform: translateY(10.11213px) translateX(2.55px);
            transform: translateY(10.11213px) translateX(2.55px);
  }
  69% {
    -webkit-transform: translateY(9.58276px) translateX(2.85px);
            transform: translateY(9.58276px) translateX(2.85px);
  }
  71% {
    -webkit-transform: translateY(9.17826px) translateX(3.15px);
            transform: translateY(9.17826px) translateX(3.15px);
  }
  73% {
    -webkit-transform: translateY(8.90499px) translateX(3.45px);
            transform: translateY(8.90499px) translateX(3.45px);
  }
  75% {
    -webkit-transform: translateY(8.76727px) translateX(3.75px);
            transform: translateY(8.76727px) translateX(3.75px);
  }
  77% {
    -webkit-transform: translateY(8.76727px) translateX(4.05px);
            transform: translateY(8.76727px) translateX(4.05px);
  }
  79% {
    -webkit-transform: translateY(8.90499px) translateX(4.35px);
            transform: translateY(8.90499px) translateX(4.35px);
  }
  81% {
    -webkit-transform: translateY(9.17826px) translateX(4.65px);
            transform: translateY(9.17826px) translateX(4.65px);
  }
  83% {
    -webkit-transform: translateY(9.58276px) translateX(4.95px);
            transform: translateY(9.58276px) translateX(4.95px);
  }
  85% {
    -webkit-transform: translateY(10.11213px) translateX(5.25px);
            transform: translateY(10.11213px) translateX(5.25px);
  }
  87% {
    -webkit-transform: translateY(10.75801px) translateX(5.55px);
            transform: translateY(10.75801px) translateX(5.55px);
  }
  89% {
    -webkit-transform: translateY(11.51021px) translateX(5.85px);
            transform: translateY(11.51021px) translateX(5.85px);
  }
  91% {
    -webkit-transform: translateY(12.35688px) translateX(6.15px);
            transform: translateY(12.35688px) translateX(6.15px);
  }
  93% {
    -webkit-transform: translateY(13.28466px) translateX(6.45px);
            transform: translateY(13.28466px) translateX(6.45px);
  }
  95% {
    -webkit-transform: translateY(14.27891px) translateX(6.75px);
            transform: translateY(14.27891px) translateX(6.75px);
  }
  97% {
    -webkit-transform: translateY(15.32396px) translateX(7.05px);
            transform: translateY(15.32396px) translateX(7.05px);
  }
  99% {
    -webkit-transform: translateY(16.40333px) translateX(7.35px);
            transform: translateY(16.40333px) translateX(7.35px);
  }
}
@keyframes hailMiddle2 {
  50% {
    -webkit-transform: translateY(17.5px);
            transform: translateY(17.5px);
  }
  51% {
    -webkit-transform: translateY(17.5px) translateX(0.15px);
            transform: translateY(17.5px) translateX(0.15px);
  }
  53% {
    -webkit-transform: translateY(16.40333px) translateX(0.45px);
            transform: translateY(16.40333px) translateX(0.45px);
  }
  55% {
    -webkit-transform: translateY(15.32396px) translateX(0.75px);
            transform: translateY(15.32396px) translateX(0.75px);
  }
  57% {
    -webkit-transform: translateY(14.27891px) translateX(1.05px);
            transform: translateY(14.27891px) translateX(1.05px);
  }
  59% {
    -webkit-transform: translateY(13.28466px) translateX(1.35px);
            transform: translateY(13.28466px) translateX(1.35px);
  }
  61% {
    -webkit-transform: translateY(12.35688px) translateX(1.65px);
            transform: translateY(12.35688px) translateX(1.65px);
  }
  63% {
    -webkit-transform: translateY(11.51021px) translateX(1.95px);
            transform: translateY(11.51021px) translateX(1.95px);
  }
  65% {
    -webkit-transform: translateY(10.75801px) translateX(2.25px);
            transform: translateY(10.75801px) translateX(2.25px);
  }
  67% {
    -webkit-transform: translateY(10.11213px) translateX(2.55px);
            transform: translateY(10.11213px) translateX(2.55px);
  }
  69% {
    -webkit-transform: translateY(9.58276px) translateX(2.85px);
            transform: translateY(9.58276px) translateX(2.85px);
  }
  71% {
    -webkit-transform: translateY(9.17826px) translateX(3.15px);
            transform: translateY(9.17826px) translateX(3.15px);
  }
  73% {
    -webkit-transform: translateY(8.90499px) translateX(3.45px);
            transform: translateY(8.90499px) translateX(3.45px);
  }
  75% {
    -webkit-transform: translateY(8.76727px) translateX(3.75px);
            transform: translateY(8.76727px) translateX(3.75px);
  }
  77% {
    -webkit-transform: translateY(8.76727px) translateX(4.05px);
            transform: translateY(8.76727px) translateX(4.05px);
  }
  79% {
    -webkit-transform: translateY(8.90499px) translateX(4.35px);
            transform: translateY(8.90499px) translateX(4.35px);
  }
  81% {
    -webkit-transform: translateY(9.17826px) translateX(4.65px);
            transform: translateY(9.17826px) translateX(4.65px);
  }
  83% {
    -webkit-transform: translateY(9.58276px) translateX(4.95px);
            transform: translateY(9.58276px) translateX(4.95px);
  }
  85% {
    -webkit-transform: translateY(10.11213px) translateX(5.25px);
            transform: translateY(10.11213px) translateX(5.25px);
  }
  87% {
    -webkit-transform: translateY(10.75801px) translateX(5.55px);
            transform: translateY(10.75801px) translateX(5.55px);
  }
  89% {
    -webkit-transform: translateY(11.51021px) translateX(5.85px);
            transform: translateY(11.51021px) translateX(5.85px);
  }
  91% {
    -webkit-transform: translateY(12.35688px) translateX(6.15px);
            transform: translateY(12.35688px) translateX(6.15px);
  }
  93% {
    -webkit-transform: translateY(13.28466px) translateX(6.45px);
            transform: translateY(13.28466px) translateX(6.45px);
  }
  95% {
    -webkit-transform: translateY(14.27891px) translateX(6.75px);
            transform: translateY(14.27891px) translateX(6.75px);
  }
  97% {
    -webkit-transform: translateY(15.32396px) translateX(7.05px);
            transform: translateY(15.32396px) translateX(7.05px);
  }
  99% {
    -webkit-transform: translateY(16.40333px) translateX(7.35px);
            transform: translateY(16.40333px) translateX(7.35px);
  }
}
@-webkit-keyframes hailRight {
  50% {
    -webkit-transform: translateY(17.5px);
            transform: translateY(17.5px);
  }
  51% {
    -webkit-transform: translateY(17.5px) translateX(0.3px);
            transform: translateY(17.5px) translateX(0.3px);
  }
  53% {
    -webkit-transform: translateY(16.40333px) translateX(0.9px);
            transform: translateY(16.40333px) translateX(0.9px);
  }
  55% {
    -webkit-transform: translateY(15.32396px) translateX(1.5px);
            transform: translateY(15.32396px) translateX(1.5px);
  }
  57% {
    -webkit-transform: translateY(14.27891px) translateX(2.1px);
            transform: translateY(14.27891px) translateX(2.1px);
  }
  59% {
    -webkit-transform: translateY(13.28466px) translateX(2.7px);
            transform: translateY(13.28466px) translateX(2.7px);
  }
  61% {
    -webkit-transform: translateY(12.35688px) translateX(3.3px);
            transform: translateY(12.35688px) translateX(3.3px);
  }
  63% {
    -webkit-transform: translateY(11.51021px) translateX(3.9px);
            transform: translateY(11.51021px) translateX(3.9px);
  }
  65% {
    -webkit-transform: translateY(10.75801px) translateX(4.5px);
            transform: translateY(10.75801px) translateX(4.5px);
  }
  67% {
    -webkit-transform: translateY(10.11213px) translateX(5.1px);
            transform: translateY(10.11213px) translateX(5.1px);
  }
  69% {
    -webkit-transform: translateY(9.58276px) translateX(5.7px);
            transform: translateY(9.58276px) translateX(5.7px);
  }
  71% {
    -webkit-transform: translateY(9.17826px) translateX(6.3px);
            transform: translateY(9.17826px) translateX(6.3px);
  }
  73% {
    -webkit-transform: translateY(8.90499px) translateX(6.9px);
            transform: translateY(8.90499px) translateX(6.9px);
  }
  75% {
    -webkit-transform: translateY(8.76727px) translateX(7.5px);
            transform: translateY(8.76727px) translateX(7.5px);
  }
  77% {
    -webkit-transform: translateY(8.76727px) translateX(8.1px);
            transform: translateY(8.76727px) translateX(8.1px);
  }
  79% {
    -webkit-transform: translateY(8.90499px) translateX(8.7px);
            transform: translateY(8.90499px) translateX(8.7px);
  }
  81% {
    -webkit-transform: translateY(9.17826px) translateX(9.3px);
            transform: translateY(9.17826px) translateX(9.3px);
  }
  83% {
    -webkit-transform: translateY(9.58276px) translateX(9.9px);
            transform: translateY(9.58276px) translateX(9.9px);
  }
  85% {
    -webkit-transform: translateY(10.11213px) translateX(10.5px);
            transform: translateY(10.11213px) translateX(10.5px);
  }
  87% {
    -webkit-transform: translateY(10.75801px) translateX(11.1px);
            transform: translateY(10.75801px) translateX(11.1px);
  }
  89% {
    -webkit-transform: translateY(11.51021px) translateX(11.7px);
            transform: translateY(11.51021px) translateX(11.7px);
  }
  91% {
    -webkit-transform: translateY(12.35688px) translateX(12.3px);
            transform: translateY(12.35688px) translateX(12.3px);
  }
  93% {
    -webkit-transform: translateY(13.28466px) translateX(12.9px);
            transform: translateY(13.28466px) translateX(12.9px);
  }
  95% {
    -webkit-transform: translateY(14.27891px) translateX(13.5px);
            transform: translateY(14.27891px) translateX(13.5px);
  }
  97% {
    -webkit-transform: translateY(15.32396px) translateX(14.1px);
            transform: translateY(15.32396px) translateX(14.1px);
  }
  99% {
    -webkit-transform: translateY(16.40333px) translateX(14.7px);
            transform: translateY(16.40333px) translateX(14.7px);
  }
}
@keyframes hailRight {
  50% {
    -webkit-transform: translateY(17.5px);
            transform: translateY(17.5px);
  }
  51% {
    -webkit-transform: translateY(17.5px) translateX(0.3px);
            transform: translateY(17.5px) translateX(0.3px);
  }
  53% {
    -webkit-transform: translateY(16.40333px) translateX(0.9px);
            transform: translateY(16.40333px) translateX(0.9px);
  }
  55% {
    -webkit-transform: translateY(15.32396px) translateX(1.5px);
            transform: translateY(15.32396px) translateX(1.5px);
  }
  57% {
    -webkit-transform: translateY(14.27891px) translateX(2.1px);
            transform: translateY(14.27891px) translateX(2.1px);
  }
  59% {
    -webkit-transform: translateY(13.28466px) translateX(2.7px);
            transform: translateY(13.28466px) translateX(2.7px);
  }
  61% {
    -webkit-transform: translateY(12.35688px) translateX(3.3px);
            transform: translateY(12.35688px) translateX(3.3px);
  }
  63% {
    -webkit-transform: translateY(11.51021px) translateX(3.9px);
            transform: translateY(11.51021px) translateX(3.9px);
  }
  65% {
    -webkit-transform: translateY(10.75801px) translateX(4.5px);
            transform: translateY(10.75801px) translateX(4.5px);
  }
  67% {
    -webkit-transform: translateY(10.11213px) translateX(5.1px);
            transform: translateY(10.11213px) translateX(5.1px);
  }
  69% {
    -webkit-transform: translateY(9.58276px) translateX(5.7px);
            transform: translateY(9.58276px) translateX(5.7px);
  }
  71% {
    -webkit-transform: translateY(9.17826px) translateX(6.3px);
            transform: translateY(9.17826px) translateX(6.3px);
  }
  73% {
    -webkit-transform: translateY(8.90499px) translateX(6.9px);
            transform: translateY(8.90499px) translateX(6.9px);
  }
  75% {
    -webkit-transform: translateY(8.76727px) translateX(7.5px);
            transform: translateY(8.76727px) translateX(7.5px);
  }
  77% {
    -webkit-transform: translateY(8.76727px) translateX(8.1px);
            transform: translateY(8.76727px) translateX(8.1px);
  }
  79% {
    -webkit-transform: translateY(8.90499px) translateX(8.7px);
            transform: translateY(8.90499px) translateX(8.7px);
  }
  81% {
    -webkit-transform: translateY(9.17826px) translateX(9.3px);
            transform: translateY(9.17826px) translateX(9.3px);
  }
  83% {
    -webkit-transform: translateY(9.58276px) translateX(9.9px);
            transform: translateY(9.58276px) translateX(9.9px);
  }
  85% {
    -webkit-transform: translateY(10.11213px) translateX(10.5px);
            transform: translateY(10.11213px) translateX(10.5px);
  }
  87% {
    -webkit-transform: translateY(10.75801px) translateX(11.1px);
            transform: translateY(10.75801px) translateX(11.1px);
  }
  89% {
    -webkit-transform: translateY(11.51021px) translateX(11.7px);
            transform: translateY(11.51021px) translateX(11.7px);
  }
  91% {
    -webkit-transform: translateY(12.35688px) translateX(12.3px);
            transform: translateY(12.35688px) translateX(12.3px);
  }
  93% {
    -webkit-transform: translateY(13.28466px) translateX(12.9px);
            transform: translateY(13.28466px) translateX(12.9px);
  }
  95% {
    -webkit-transform: translateY(14.27891px) translateX(13.5px);
            transform: translateY(14.27891px) translateX(13.5px);
  }
  97% {
    -webkit-transform: translateY(15.32396px) translateX(14.1px);
            transform: translateY(15.32396px) translateX(14.1px);
  }
  99% {
    -webkit-transform: translateY(16.40333px) translateX(14.7px);
            transform: translateY(16.40333px) translateX(14.7px);
  }
}
@-webkit-keyframes fillOpacity {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
  100% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
}
@keyframes fillOpacity {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
  100% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
}
@-webkit-keyframes fillOpacity2 {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
  50% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  100% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
}
@keyframes fillOpacity2 {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
  50% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  100% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
}
@-webkit-keyframes lightningFlash {
  0% {
    fill-opacity: 0;
  }
  1% {
    fill-opacity: 1;
  }
  2% {
    fill-opacity: 0;
  }
  50% {
    fill-opacity: 0;
  }
  51% {
    fill-opacity: 1;
  }
  52% {
    fill-opacity: 0;
  }
  53% {
    fill-opacity: 1;
  }
  54% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 0;
  }
}
@keyframes lightningFlash {
  0% {
    fill-opacity: 0;
  }
  1% {
    fill-opacity: 1;
  }
  2% {
    fill-opacity: 0;
  }
  50% {
    fill-opacity: 0;
  }
  51% {
    fill-opacity: 1;
  }
  52% {
    fill-opacity: 0;
  }
  53% {
    fill-opacity: 1;
  }
  54% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 0;
  }
}
@-webkit-keyframes snowFall {
  0% {
    -webkit-transform: translateY(0px) translateX(0px);
            transform: translateY(0px) translateX(0px);
  }
  2% {
    -webkit-transform: translateY(0.35px) translateX(0.75349px);
            transform: translateY(0.35px) translateX(0.75349px);
  }
  4% {
    -webkit-transform: translateY(0.7px) translateX(1.44133px);
            transform: translateY(0.7px) translateX(1.44133px);
  }
  6% {
    -webkit-transform: translateY(1.05px) translateX(2.06119px);
            transform: translateY(1.05px) translateX(2.06119px);
  }
  8% {
    -webkit-transform: translateY(1.4px) translateX(2.61124px);
            transform: translateY(1.4px) translateX(2.61124px);
  }
  10% {
    -webkit-transform: translateY(1.75px) translateX(3.09017px);
            transform: translateY(1.75px) translateX(3.09017px);
  }
  12% {
    -webkit-transform: translateY(2.1px) translateX(3.49718px);
            transform: translateY(2.1px) translateX(3.49718px);
  }
  14% {
    -webkit-transform: translateY(2.45px) translateX(3.83201px);
            transform: translateY(2.45px) translateX(3.83201px);
  }
  16% {
    -webkit-transform: translateY(2.8px) translateX(4.09491px);
            transform: translateY(2.8px) translateX(4.09491px);
  }
  18% {
    -webkit-transform: translateY(3.15px) translateX(4.28661px);
            transform: translateY(3.15px) translateX(4.28661px);
  }
  20% {
    -webkit-transform: translateY(3.5px) translateX(4.40839px);
            transform: translateY(3.5px) translateX(4.40839px);
  }
  22% {
    -webkit-transform: translateY(3.85px) translateX(4.46197px);
            transform: translateY(3.85px) translateX(4.46197px);
  }
  24% {
    -webkit-transform: translateY(4.2px) translateX(4.44956px);
            transform: translateY(4.2px) translateX(4.44956px);
  }
  26% {
    -webkit-transform: translateY(4.55px) translateX(4.37381px);
            transform: translateY(4.55px) translateX(4.37381px);
  }
  28% {
    -webkit-transform: translateY(4.9px) translateX(4.23782px);
            transform: translateY(4.9px) translateX(4.23782px);
  }
  30% {
    -webkit-transform: translateY(5.25px) translateX(4.04508px);
            transform: translateY(5.25px) translateX(4.04508px);
  }
  32% {
    -webkit-transform: translateY(5.6px) translateX(3.79948px);
            transform: translateY(5.6px) translateX(3.79948px);
  }
  34% {
    -webkit-transform: translateY(5.95px) translateX(3.50523px);
            transform: translateY(5.95px) translateX(3.50523px);
  }
  36% {
    -webkit-transform: translateY(6.3px) translateX(3.16689px);
            transform: translateY(6.3px) translateX(3.16689px);
  }
  38% {
    -webkit-transform: translateY(6.65px) translateX(2.78933px);
            transform: translateY(6.65px) translateX(2.78933px);
  }
  40% {
    -webkit-transform: translateY(7px) translateX(2.37764px);
            transform: translateY(7px) translateX(2.37764px);
  }
  42% {
    -webkit-transform: translateY(7.35px) translateX(1.93717px);
            transform: translateY(7.35px) translateX(1.93717px);
  }
  44% {
    -webkit-transform: translateY(7.7px) translateX(1.47343px);
            transform: translateY(7.7px) translateX(1.47343px);
  }
  46% {
    -webkit-transform: translateY(8.05px) translateX(0.99211px);
            transform: translateY(8.05px) translateX(0.99211px);
  }
  48% {
    -webkit-transform: translateY(8.4px) translateX(0.49901px);
            transform: translateY(8.4px) translateX(0.49901px);
  }
  50% {
    -webkit-transform: translateY(8.75px) translateX(0px);
            transform: translateY(8.75px) translateX(0px);
  }
  52% {
    -webkit-transform: translateY(9.1px) translateX(-0.49901px);
            transform: translateY(9.1px) translateX(-0.49901px);
  }
  54% {
    -webkit-transform: translateY(9.45px) translateX(-0.99211px);
            transform: translateY(9.45px) translateX(-0.99211px);
  }
  56% {
    -webkit-transform: translateY(9.8px) translateX(-1.47343px);
            transform: translateY(9.8px) translateX(-1.47343px);
  }
  58% {
    -webkit-transform: translateY(10.15px) translateX(-1.93717px);
            transform: translateY(10.15px) translateX(-1.93717px);
  }
  60% {
    -webkit-transform: translateY(10.5px) translateX(-2.37764px);
            transform: translateY(10.5px) translateX(-2.37764px);
  }
  62% {
    -webkit-transform: translateY(10.85px) translateX(-2.78933px);
            transform: translateY(10.85px) translateX(-2.78933px);
  }
  64% {
    -webkit-transform: translateY(11.2px) translateX(-3.16689px);
            transform: translateY(11.2px) translateX(-3.16689px);
  }
  66% {
    -webkit-transform: translateY(11.55px) translateX(-3.50523px);
            transform: translateY(11.55px) translateX(-3.50523px);
  }
  68% {
    -webkit-transform: translateY(11.9px) translateX(-3.79948px);
            transform: translateY(11.9px) translateX(-3.79948px);
  }
  70% {
    -webkit-transform: translateY(12.25px) translateX(-4.04508px);
            transform: translateY(12.25px) translateX(-4.04508px);
  }
  72% {
    -webkit-transform: translateY(12.6px) translateX(-4.23782px);
            transform: translateY(12.6px) translateX(-4.23782px);
  }
  74% {
    -webkit-transform: translateY(12.95px) translateX(-4.37381px);
            transform: translateY(12.95px) translateX(-4.37381px);
  }
  76% {
    -webkit-transform: translateY(13.3px) translateX(-4.44956px);
            transform: translateY(13.3px) translateX(-4.44956px);
  }
  78% {
    -webkit-transform: translateY(13.65px) translateX(-4.46197px);
            transform: translateY(13.65px) translateX(-4.46197px);
  }
  80% {
    -webkit-transform: translateY(14px) translateX(-4.40839px);
            transform: translateY(14px) translateX(-4.40839px);
  }
  82% {
    -webkit-transform: translateY(14.35px) translateX(-4.28661px);
            transform: translateY(14.35px) translateX(-4.28661px);
  }
  84% {
    -webkit-transform: translateY(14.7px) translateX(-4.09491px);
            transform: translateY(14.7px) translateX(-4.09491px);
  }
  86% {
    -webkit-transform: translateY(15.05px) translateX(-3.83201px);
            transform: translateY(15.05px) translateX(-3.83201px);
  }
  88% {
    -webkit-transform: translateY(15.4px) translateX(-3.49718px);
            transform: translateY(15.4px) translateX(-3.49718px);
  }
  90% {
    -webkit-transform: translateY(15.75px) translateX(-3.09017px);
            transform: translateY(15.75px) translateX(-3.09017px);
  }
  92% {
    -webkit-transform: translateY(16.1px) translateX(-2.61124px);
            transform: translateY(16.1px) translateX(-2.61124px);
  }
  94% {
    -webkit-transform: translateY(16.45px) translateX(-2.06119px);
            transform: translateY(16.45px) translateX(-2.06119px);
  }
  96% {
    -webkit-transform: translateY(16.8px) translateX(-1.44133px);
            transform: translateY(16.8px) translateX(-1.44133px);
  }
  98% {
    -webkit-transform: translateY(17.15px) translateX(-0.75349px);
            transform: translateY(17.15px) translateX(-0.75349px);
  }
  100% {
    -webkit-transform: translateY(17.5px) translateX(0px);
            transform: translateY(17.5px) translateX(0px);
  }
}
@keyframes snowFall {
  0% {
    -webkit-transform: translateY(0px) translateX(0px);
            transform: translateY(0px) translateX(0px);
  }
  2% {
    -webkit-transform: translateY(0.35px) translateX(0.75349px);
            transform: translateY(0.35px) translateX(0.75349px);
  }
  4% {
    -webkit-transform: translateY(0.7px) translateX(1.44133px);
            transform: translateY(0.7px) translateX(1.44133px);
  }
  6% {
    -webkit-transform: translateY(1.05px) translateX(2.06119px);
            transform: translateY(1.05px) translateX(2.06119px);
  }
  8% {
    -webkit-transform: translateY(1.4px) translateX(2.61124px);
            transform: translateY(1.4px) translateX(2.61124px);
  }
  10% {
    -webkit-transform: translateY(1.75px) translateX(3.09017px);
            transform: translateY(1.75px) translateX(3.09017px);
  }
  12% {
    -webkit-transform: translateY(2.1px) translateX(3.49718px);
            transform: translateY(2.1px) translateX(3.49718px);
  }
  14% {
    -webkit-transform: translateY(2.45px) translateX(3.83201px);
            transform: translateY(2.45px) translateX(3.83201px);
  }
  16% {
    -webkit-transform: translateY(2.8px) translateX(4.09491px);
            transform: translateY(2.8px) translateX(4.09491px);
  }
  18% {
    -webkit-transform: translateY(3.15px) translateX(4.28661px);
            transform: translateY(3.15px) translateX(4.28661px);
  }
  20% {
    -webkit-transform: translateY(3.5px) translateX(4.40839px);
            transform: translateY(3.5px) translateX(4.40839px);
  }
  22% {
    -webkit-transform: translateY(3.85px) translateX(4.46197px);
            transform: translateY(3.85px) translateX(4.46197px);
  }
  24% {
    -webkit-transform: translateY(4.2px) translateX(4.44956px);
            transform: translateY(4.2px) translateX(4.44956px);
  }
  26% {
    -webkit-transform: translateY(4.55px) translateX(4.37381px);
            transform: translateY(4.55px) translateX(4.37381px);
  }
  28% {
    -webkit-transform: translateY(4.9px) translateX(4.23782px);
            transform: translateY(4.9px) translateX(4.23782px);
  }
  30% {
    -webkit-transform: translateY(5.25px) translateX(4.04508px);
            transform: translateY(5.25px) translateX(4.04508px);
  }
  32% {
    -webkit-transform: translateY(5.6px) translateX(3.79948px);
            transform: translateY(5.6px) translateX(3.79948px);
  }
  34% {
    -webkit-transform: translateY(5.95px) translateX(3.50523px);
            transform: translateY(5.95px) translateX(3.50523px);
  }
  36% {
    -webkit-transform: translateY(6.3px) translateX(3.16689px);
            transform: translateY(6.3px) translateX(3.16689px);
  }
  38% {
    -webkit-transform: translateY(6.65px) translateX(2.78933px);
            transform: translateY(6.65px) translateX(2.78933px);
  }
  40% {
    -webkit-transform: translateY(7px) translateX(2.37764px);
            transform: translateY(7px) translateX(2.37764px);
  }
  42% {
    -webkit-transform: translateY(7.35px) translateX(1.93717px);
            transform: translateY(7.35px) translateX(1.93717px);
  }
  44% {
    -webkit-transform: translateY(7.7px) translateX(1.47343px);
            transform: translateY(7.7px) translateX(1.47343px);
  }
  46% {
    -webkit-transform: translateY(8.05px) translateX(0.99211px);
            transform: translateY(8.05px) translateX(0.99211px);
  }
  48% {
    -webkit-transform: translateY(8.4px) translateX(0.49901px);
            transform: translateY(8.4px) translateX(0.49901px);
  }
  50% {
    -webkit-transform: translateY(8.75px) translateX(0px);
            transform: translateY(8.75px) translateX(0px);
  }
  52% {
    -webkit-transform: translateY(9.1px) translateX(-0.49901px);
            transform: translateY(9.1px) translateX(-0.49901px);
  }
  54% {
    -webkit-transform: translateY(9.45px) translateX(-0.99211px);
            transform: translateY(9.45px) translateX(-0.99211px);
  }
  56% {
    -webkit-transform: translateY(9.8px) translateX(-1.47343px);
            transform: translateY(9.8px) translateX(-1.47343px);
  }
  58% {
    -webkit-transform: translateY(10.15px) translateX(-1.93717px);
            transform: translateY(10.15px) translateX(-1.93717px);
  }
  60% {
    -webkit-transform: translateY(10.5px) translateX(-2.37764px);
            transform: translateY(10.5px) translateX(-2.37764px);
  }
  62% {
    -webkit-transform: translateY(10.85px) translateX(-2.78933px);
            transform: translateY(10.85px) translateX(-2.78933px);
  }
  64% {
    -webkit-transform: translateY(11.2px) translateX(-3.16689px);
            transform: translateY(11.2px) translateX(-3.16689px);
  }
  66% {
    -webkit-transform: translateY(11.55px) translateX(-3.50523px);
            transform: translateY(11.55px) translateX(-3.50523px);
  }
  68% {
    -webkit-transform: translateY(11.9px) translateX(-3.79948px);
            transform: translateY(11.9px) translateX(-3.79948px);
  }
  70% {
    -webkit-transform: translateY(12.25px) translateX(-4.04508px);
            transform: translateY(12.25px) translateX(-4.04508px);
  }
  72% {
    -webkit-transform: translateY(12.6px) translateX(-4.23782px);
            transform: translateY(12.6px) translateX(-4.23782px);
  }
  74% {
    -webkit-transform: translateY(12.95px) translateX(-4.37381px);
            transform: translateY(12.95px) translateX(-4.37381px);
  }
  76% {
    -webkit-transform: translateY(13.3px) translateX(-4.44956px);
            transform: translateY(13.3px) translateX(-4.44956px);
  }
  78% {
    -webkit-transform: translateY(13.65px) translateX(-4.46197px);
            transform: translateY(13.65px) translateX(-4.46197px);
  }
  80% {
    -webkit-transform: translateY(14px) translateX(-4.40839px);
            transform: translateY(14px) translateX(-4.40839px);
  }
  82% {
    -webkit-transform: translateY(14.35px) translateX(-4.28661px);
            transform: translateY(14.35px) translateX(-4.28661px);
  }
  84% {
    -webkit-transform: translateY(14.7px) translateX(-4.09491px);
            transform: translateY(14.7px) translateX(-4.09491px);
  }
  86% {
    -webkit-transform: translateY(15.05px) translateX(-3.83201px);
            transform: translateY(15.05px) translateX(-3.83201px);
  }
  88% {
    -webkit-transform: translateY(15.4px) translateX(-3.49718px);
            transform: translateY(15.4px) translateX(-3.49718px);
  }
  90% {
    -webkit-transform: translateY(15.75px) translateX(-3.09017px);
            transform: translateY(15.75px) translateX(-3.09017px);
  }
  92% {
    -webkit-transform: translateY(16.1px) translateX(-2.61124px);
            transform: translateY(16.1px) translateX(-2.61124px);
  }
  94% {
    -webkit-transform: translateY(16.45px) translateX(-2.06119px);
            transform: translateY(16.45px) translateX(-2.06119px);
  }
  96% {
    -webkit-transform: translateY(16.8px) translateX(-1.44133px);
            transform: translateY(16.8px) translateX(-1.44133px);
  }
  98% {
    -webkit-transform: translateY(17.15px) translateX(-0.75349px);
            transform: translateY(17.15px) translateX(-0.75349px);
  }
  100% {
    -webkit-transform: translateY(17.5px) translateX(0px);
            transform: translateY(17.5px) translateX(0px);
  }
}
@-webkit-keyframes snowFall2 {
  0% {
    -webkit-transform: translateY(0px) translateX(0px);
            transform: translateY(0px) translateX(0px);
  }
  2% {
    -webkit-transform: translateY(0.35px) translateX(-0.75349px);
            transform: translateY(0.35px) translateX(-0.75349px);
  }
  4% {
    -webkit-transform: translateY(0.7px) translateX(-1.44133px);
            transform: translateY(0.7px) translateX(-1.44133px);
  }
  6% {
    -webkit-transform: translateY(1.05px) translateX(-2.06119px);
            transform: translateY(1.05px) translateX(-2.06119px);
  }
  8% {
    -webkit-transform: translateY(1.4px) translateX(-2.61124px);
            transform: translateY(1.4px) translateX(-2.61124px);
  }
  10% {
    -webkit-transform: translateY(1.75px) translateX(-3.09017px);
            transform: translateY(1.75px) translateX(-3.09017px);
  }
  12% {
    -webkit-transform: translateY(2.1px) translateX(-3.49718px);
            transform: translateY(2.1px) translateX(-3.49718px);
  }
  14% {
    -webkit-transform: translateY(2.45px) translateX(-3.83201px);
            transform: translateY(2.45px) translateX(-3.83201px);
  }
  16% {
    -webkit-transform: translateY(2.8px) translateX(-4.09491px);
            transform: translateY(2.8px) translateX(-4.09491px);
  }
  18% {
    -webkit-transform: translateY(3.15px) translateX(-4.28661px);
            transform: translateY(3.15px) translateX(-4.28661px);
  }
  20% {
    -webkit-transform: translateY(3.5px) translateX(-4.40839px);
            transform: translateY(3.5px) translateX(-4.40839px);
  }
  22% {
    -webkit-transform: translateY(3.85px) translateX(-4.46197px);
            transform: translateY(3.85px) translateX(-4.46197px);
  }
  24% {
    -webkit-transform: translateY(4.2px) translateX(-4.44956px);
            transform: translateY(4.2px) translateX(-4.44956px);
  }
  26% {
    -webkit-transform: translateY(4.55px) translateX(-4.37381px);
            transform: translateY(4.55px) translateX(-4.37381px);
  }
  28% {
    -webkit-transform: translateY(4.9px) translateX(-4.23782px);
            transform: translateY(4.9px) translateX(-4.23782px);
  }
  30% {
    -webkit-transform: translateY(5.25px) translateX(-4.04508px);
            transform: translateY(5.25px) translateX(-4.04508px);
  }
  32% {
    -webkit-transform: translateY(5.6px) translateX(-3.79948px);
            transform: translateY(5.6px) translateX(-3.79948px);
  }
  34% {
    -webkit-transform: translateY(5.95px) translateX(-3.50523px);
            transform: translateY(5.95px) translateX(-3.50523px);
  }
  36% {
    -webkit-transform: translateY(6.3px) translateX(-3.16689px);
            transform: translateY(6.3px) translateX(-3.16689px);
  }
  38% {
    -webkit-transform: translateY(6.65px) translateX(-2.78933px);
            transform: translateY(6.65px) translateX(-2.78933px);
  }
  40% {
    -webkit-transform: translateY(7px) translateX(-2.37764px);
            transform: translateY(7px) translateX(-2.37764px);
  }
  42% {
    -webkit-transform: translateY(7.35px) translateX(-1.93717px);
            transform: translateY(7.35px) translateX(-1.93717px);
  }
  44% {
    -webkit-transform: translateY(7.7px) translateX(-1.47343px);
            transform: translateY(7.7px) translateX(-1.47343px);
  }
  46% {
    -webkit-transform: translateY(8.05px) translateX(-0.99211px);
            transform: translateY(8.05px) translateX(-0.99211px);
  }
  48% {
    -webkit-transform: translateY(8.4px) translateX(-0.49901px);
            transform: translateY(8.4px) translateX(-0.49901px);
  }
  50% {
    -webkit-transform: translateY(8.75px) translateX(0px);
            transform: translateY(8.75px) translateX(0px);
  }
  52% {
    -webkit-transform: translateY(9.1px) translateX(0.49901px);
            transform: translateY(9.1px) translateX(0.49901px);
  }
  54% {
    -webkit-transform: translateY(9.45px) translateX(0.99211px);
            transform: translateY(9.45px) translateX(0.99211px);
  }
  56% {
    -webkit-transform: translateY(9.8px) translateX(1.47343px);
            transform: translateY(9.8px) translateX(1.47343px);
  }
  58% {
    -webkit-transform: translateY(10.15px) translateX(1.93717px);
            transform: translateY(10.15px) translateX(1.93717px);
  }
  60% {
    -webkit-transform: translateY(10.5px) translateX(2.37764px);
            transform: translateY(10.5px) translateX(2.37764px);
  }
  62% {
    -webkit-transform: translateY(10.85px) translateX(2.78933px);
            transform: translateY(10.85px) translateX(2.78933px);
  }
  64% {
    -webkit-transform: translateY(11.2px) translateX(3.16689px);
            transform: translateY(11.2px) translateX(3.16689px);
  }
  66% {
    -webkit-transform: translateY(11.55px) translateX(3.50523px);
            transform: translateY(11.55px) translateX(3.50523px);
  }
  68% {
    -webkit-transform: translateY(11.9px) translateX(3.79948px);
            transform: translateY(11.9px) translateX(3.79948px);
  }
  70% {
    -webkit-transform: translateY(12.25px) translateX(4.04508px);
            transform: translateY(12.25px) translateX(4.04508px);
  }
  72% {
    -webkit-transform: translateY(12.6px) translateX(4.23782px);
            transform: translateY(12.6px) translateX(4.23782px);
  }
  74% {
    -webkit-transform: translateY(12.95px) translateX(4.37381px);
            transform: translateY(12.95px) translateX(4.37381px);
  }
  76% {
    -webkit-transform: translateY(13.3px) translateX(4.44956px);
            transform: translateY(13.3px) translateX(4.44956px);
  }
  78% {
    -webkit-transform: translateY(13.65px) translateX(4.46197px);
            transform: translateY(13.65px) translateX(4.46197px);
  }
  80% {
    -webkit-transform: translateY(14px) translateX(4.40839px);
            transform: translateY(14px) translateX(4.40839px);
  }
  82% {
    -webkit-transform: translateY(14.35px) translateX(4.28661px);
            transform: translateY(14.35px) translateX(4.28661px);
  }
  84% {
    -webkit-transform: translateY(14.7px) translateX(4.09491px);
            transform: translateY(14.7px) translateX(4.09491px);
  }
  86% {
    -webkit-transform: translateY(15.05px) translateX(3.83201px);
            transform: translateY(15.05px) translateX(3.83201px);
  }
  88% {
    -webkit-transform: translateY(15.4px) translateX(3.49718px);
            transform: translateY(15.4px) translateX(3.49718px);
  }
  90% {
    -webkit-transform: translateY(15.75px) translateX(3.09017px);
            transform: translateY(15.75px) translateX(3.09017px);
  }
  92% {
    -webkit-transform: translateY(16.1px) translateX(2.61124px);
            transform: translateY(16.1px) translateX(2.61124px);
  }
  94% {
    -webkit-transform: translateY(16.45px) translateX(2.06119px);
            transform: translateY(16.45px) translateX(2.06119px);
  }
  96% {
    -webkit-transform: translateY(16.8px) translateX(1.44133px);
            transform: translateY(16.8px) translateX(1.44133px);
  }
  98% {
    -webkit-transform: translateY(17.15px) translateX(0.75349px);
            transform: translateY(17.15px) translateX(0.75349px);
  }
  100% {
    -webkit-transform: translateY(17.5px) translateX(0px);
            transform: translateY(17.5px) translateX(0px);
  }
}
@keyframes snowFall2 {
  0% {
    -webkit-transform: translateY(0px) translateX(0px);
            transform: translateY(0px) translateX(0px);
  }
  2% {
    -webkit-transform: translateY(0.35px) translateX(-0.75349px);
            transform: translateY(0.35px) translateX(-0.75349px);
  }
  4% {
    -webkit-transform: translateY(0.7px) translateX(-1.44133px);
            transform: translateY(0.7px) translateX(-1.44133px);
  }
  6% {
    -webkit-transform: translateY(1.05px) translateX(-2.06119px);
            transform: translateY(1.05px) translateX(-2.06119px);
  }
  8% {
    -webkit-transform: translateY(1.4px) translateX(-2.61124px);
            transform: translateY(1.4px) translateX(-2.61124px);
  }
  10% {
    -webkit-transform: translateY(1.75px) translateX(-3.09017px);
            transform: translateY(1.75px) translateX(-3.09017px);
  }
  12% {
    -webkit-transform: translateY(2.1px) translateX(-3.49718px);
            transform: translateY(2.1px) translateX(-3.49718px);
  }
  14% {
    -webkit-transform: translateY(2.45px) translateX(-3.83201px);
            transform: translateY(2.45px) translateX(-3.83201px);
  }
  16% {
    -webkit-transform: translateY(2.8px) translateX(-4.09491px);
            transform: translateY(2.8px) translateX(-4.09491px);
  }
  18% {
    -webkit-transform: translateY(3.15px) translateX(-4.28661px);
            transform: translateY(3.15px) translateX(-4.28661px);
  }
  20% {
    -webkit-transform: translateY(3.5px) translateX(-4.40839px);
            transform: translateY(3.5px) translateX(-4.40839px);
  }
  22% {
    -webkit-transform: translateY(3.85px) translateX(-4.46197px);
            transform: translateY(3.85px) translateX(-4.46197px);
  }
  24% {
    -webkit-transform: translateY(4.2px) translateX(-4.44956px);
            transform: translateY(4.2px) translateX(-4.44956px);
  }
  26% {
    -webkit-transform: translateY(4.55px) translateX(-4.37381px);
            transform: translateY(4.55px) translateX(-4.37381px);
  }
  28% {
    -webkit-transform: translateY(4.9px) translateX(-4.23782px);
            transform: translateY(4.9px) translateX(-4.23782px);
  }
  30% {
    -webkit-transform: translateY(5.25px) translateX(-4.04508px);
            transform: translateY(5.25px) translateX(-4.04508px);
  }
  32% {
    -webkit-transform: translateY(5.6px) translateX(-3.79948px);
            transform: translateY(5.6px) translateX(-3.79948px);
  }
  34% {
    -webkit-transform: translateY(5.95px) translateX(-3.50523px);
            transform: translateY(5.95px) translateX(-3.50523px);
  }
  36% {
    -webkit-transform: translateY(6.3px) translateX(-3.16689px);
            transform: translateY(6.3px) translateX(-3.16689px);
  }
  38% {
    -webkit-transform: translateY(6.65px) translateX(-2.78933px);
            transform: translateY(6.65px) translateX(-2.78933px);
  }
  40% {
    -webkit-transform: translateY(7px) translateX(-2.37764px);
            transform: translateY(7px) translateX(-2.37764px);
  }
  42% {
    -webkit-transform: translateY(7.35px) translateX(-1.93717px);
            transform: translateY(7.35px) translateX(-1.93717px);
  }
  44% {
    -webkit-transform: translateY(7.7px) translateX(-1.47343px);
            transform: translateY(7.7px) translateX(-1.47343px);
  }
  46% {
    -webkit-transform: translateY(8.05px) translateX(-0.99211px);
            transform: translateY(8.05px) translateX(-0.99211px);
  }
  48% {
    -webkit-transform: translateY(8.4px) translateX(-0.49901px);
            transform: translateY(8.4px) translateX(-0.49901px);
  }
  50% {
    -webkit-transform: translateY(8.75px) translateX(0px);
            transform: translateY(8.75px) translateX(0px);
  }
  52% {
    -webkit-transform: translateY(9.1px) translateX(0.49901px);
            transform: translateY(9.1px) translateX(0.49901px);
  }
  54% {
    -webkit-transform: translateY(9.45px) translateX(0.99211px);
            transform: translateY(9.45px) translateX(0.99211px);
  }
  56% {
    -webkit-transform: translateY(9.8px) translateX(1.47343px);
            transform: translateY(9.8px) translateX(1.47343px);
  }
  58% {
    -webkit-transform: translateY(10.15px) translateX(1.93717px);
            transform: translateY(10.15px) translateX(1.93717px);
  }
  60% {
    -webkit-transform: translateY(10.5px) translateX(2.37764px);
            transform: translateY(10.5px) translateX(2.37764px);
  }
  62% {
    -webkit-transform: translateY(10.85px) translateX(2.78933px);
            transform: translateY(10.85px) translateX(2.78933px);
  }
  64% {
    -webkit-transform: translateY(11.2px) translateX(3.16689px);
            transform: translateY(11.2px) translateX(3.16689px);
  }
  66% {
    -webkit-transform: translateY(11.55px) translateX(3.50523px);
            transform: translateY(11.55px) translateX(3.50523px);
  }
  68% {
    -webkit-transform: translateY(11.9px) translateX(3.79948px);
            transform: translateY(11.9px) translateX(3.79948px);
  }
  70% {
    -webkit-transform: translateY(12.25px) translateX(4.04508px);
            transform: translateY(12.25px) translateX(4.04508px);
  }
  72% {
    -webkit-transform: translateY(12.6px) translateX(4.23782px);
            transform: translateY(12.6px) translateX(4.23782px);
  }
  74% {
    -webkit-transform: translateY(12.95px) translateX(4.37381px);
            transform: translateY(12.95px) translateX(4.37381px);
  }
  76% {
    -webkit-transform: translateY(13.3px) translateX(4.44956px);
            transform: translateY(13.3px) translateX(4.44956px);
  }
  78% {
    -webkit-transform: translateY(13.65px) translateX(4.46197px);
            transform: translateY(13.65px) translateX(4.46197px);
  }
  80% {
    -webkit-transform: translateY(14px) translateX(4.40839px);
            transform: translateY(14px) translateX(4.40839px);
  }
  82% {
    -webkit-transform: translateY(14.35px) translateX(4.28661px);
            transform: translateY(14.35px) translateX(4.28661px);
  }
  84% {
    -webkit-transform: translateY(14.7px) translateX(4.09491px);
            transform: translateY(14.7px) translateX(4.09491px);
  }
  86% {
    -webkit-transform: translateY(15.05px) translateX(3.83201px);
            transform: translateY(15.05px) translateX(3.83201px);
  }
  88% {
    -webkit-transform: translateY(15.4px) translateX(3.49718px);
            transform: translateY(15.4px) translateX(3.49718px);
  }
  90% {
    -webkit-transform: translateY(15.75px) translateX(3.09017px);
            transform: translateY(15.75px) translateX(3.09017px);
  }
  92% {
    -webkit-transform: translateY(16.1px) translateX(2.61124px);
            transform: translateY(16.1px) translateX(2.61124px);
  }
  94% {
    -webkit-transform: translateY(16.45px) translateX(2.06119px);
            transform: translateY(16.45px) translateX(2.06119px);
  }
  96% {
    -webkit-transform: translateY(16.8px) translateX(1.44133px);
            transform: translateY(16.8px) translateX(1.44133px);
  }
  98% {
    -webkit-transform: translateY(17.15px) translateX(0.75349px);
            transform: translateY(17.15px) translateX(0.75349px);
  }
  100% {
    -webkit-transform: translateY(17.5px) translateX(0px);
            transform: translateY(17.5px) translateX(0px);
  }
}
/* Tornado */
@-webkit-keyframes translateTornado1 {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateX(3.997px);
            transform: translateX(3.997px);
  }
  75% {
    -webkit-transform: translateX(-3.997px);
            transform: translateX(-3.997px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes translateTornado1 {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateX(3.997px);
            transform: translateX(3.997px);
  }
  75% {
    -webkit-transform: translateX(-3.997px);
            transform: translateX(-3.997px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes translateTornado2 {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateX(2.002px);
            transform: translateX(2.002px);
  }
  75% {
    -webkit-transform: translateX(-2.002px);
            transform: translateX(-2.002px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes translateTornado2 {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateX(2.002px);
            transform: translateX(2.002px);
  }
  75% {
    -webkit-transform: translateX(-2.002px);
            transform: translateX(-2.002px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes translateTornado3 {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateX(8.001px);
            transform: translateX(8.001px);
  }
  75% {
    -webkit-transform: translateX(-8.001px);
            transform: translateX(-8.001px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes translateTornado3 {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateX(8.001px);
            transform: translateX(8.001px);
  }
  75% {
    -webkit-transform: translateX(-8.001px);
            transform: translateX(-8.001px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes translateTornado4 {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateX(5.999px);
            transform: translateX(5.999px);
  }
  75% {
    -webkit-transform: translateX(-5.999px);
            transform: translateX(-5.999px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes translateTornado4 {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateX(5.999px);
            transform: translateX(5.999px);
  }
  75% {
    -webkit-transform: translateX(-5.999px);
            transform: translateX(-5.999px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes translateTornado5 {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateX(10.003px);
            transform: translateX(10.003px);
  }
  75% {
    -webkit-transform: translateX(-10.003px);
            transform: translateX(-10.003px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes translateTornado5 {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateX(10.003px);
            transform: translateX(10.003px);
  }
  75% {
    -webkit-transform: translateX(-10.003px);
            transform: translateX(-10.003px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes translateTornado6 {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateX(5.999px);
            transform: translateX(5.999px);
  }
  75% {
    -webkit-transform: translateX(-5.999px);
            transform: translateX(-5.999px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes translateTornado6 {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateX(5.999px);
            transform: translateX(5.999px);
  }
  75% {
    -webkit-transform: translateX(-5.999px);
            transform: translateX(-5.999px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes fillOpacityLightning {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
  1% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  7% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
  50% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
  51% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  53% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
  54% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  60% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
  100% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
}
@keyframes fillOpacityLightning {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
  1% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  7% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
  50% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
  51% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  53% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
  54% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  60% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
  100% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
}
@-webkit-keyframes translateFog {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateX(2.499px);
            transform: translateX(2.499px);
  }
  75% {
    -webkit-transform: translateX(-2.499px);
            transform: translateX(-2.499px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes translateFog {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateX(2.499px);
            transform: translateX(2.499px);
  }
  75% {
    -webkit-transform: translateX(-2.499px);
            transform: translateX(-2.499px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes fillOpacityFog {
  0% {
    fill-opacity: 0.5;
    stroke-opacity: 0.5;
  }
  50% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  100% {
    fill-opacity: 0.5;
    stroke-opacity: 0.5;
  }
}
@keyframes fillOpacityFog {
  0% {
    fill-opacity: 0.5;
    stroke-opacity: 0.5;
  }
  50% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  100% {
    fill-opacity: 0.5;
    stroke-opacity: 0.5;
  }
}
@-webkit-keyframes translateSunrise {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-16.002px);
            transform: translateY(-16.002px);
  }
  100% {
    -webkit-transform: translateY(-16.002px);
            transform: translateY(-16.002px);
  }
}
@keyframes translateSunrise {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-16.002px);
            transform: translateY(-16.002px);
  }
  100% {
    -webkit-transform: translateY(-16.002px);
            transform: translateY(-16.002px);
  }
}
@-webkit-keyframes translateSunset {
  0% {
    -webkit-transform: translateY(-16.002px);
            transform: translateY(-16.002px);
  }
  25% {
    -webkit-transform: translateY(-3.997px);
            transform: translateY(-3.997px);
  }
  100% {
    -webkit-transform: translateY(-3.997px);
            transform: translateY(-3.997px);
  }
}
@keyframes translateSunset {
  0% {
    -webkit-transform: translateY(-16.002px);
            transform: translateY(-16.002px);
  }
  25% {
    -webkit-transform: translateY(-3.997px);
            transform: translateY(-3.997px);
  }
  100% {
    -webkit-transform: translateY(-3.997px);
            transform: translateY(-3.997px);
  }
}
@-webkit-keyframes translateArrowDown {
  0% {
    -webkit-transform: translateY(2.002px);
            transform: translateY(2.002px);
  }
  100% {
    -webkit-transform: translateY(4.998px);
            transform: translateY(4.998px);
  }
}
@keyframes translateArrowDown {
  0% {
    -webkit-transform: translateY(2.002px);
            transform: translateY(2.002px);
  }
  100% {
    -webkit-transform: translateY(4.998px);
            transform: translateY(4.998px);
  }
}
@-webkit-keyframes translateArrowUp {
  0% {
    -webkit-transform: translateY(-2.002px);
            transform: translateY(-2.002px);
  }
  100% {
    -webkit-transform: translateY(-4.998px);
            transform: translateY(-4.998px);
  }
}
@keyframes translateArrowUp {
  0% {
    -webkit-transform: translateY(-2.002px);
            transform: translateY(-2.002px);
  }
  100% {
    -webkit-transform: translateY(-4.998px);
            transform: translateY(-4.998px);
  }
}
@-webkit-keyframes translateWind {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateX(4.998px);
            transform: translateX(4.998px);
  }
  75% {
    -webkit-transform: translateX(-4.998px);
            transform: translateX(-4.998px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes translateWind {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateX(4.998px);
            transform: translateX(4.998px);
  }
  75% {
    -webkit-transform: translateX(-4.998px);
            transform: translateX(-4.998px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.light-blue-fill {
  fill: #A1D6FB !important;
}
.blue-fill {
  fill: #b1e3ff !important;
}
.orange-fill {
  fill: #FF9B45 !important;
}
.light-orange-fill {
  fill: #F9C496 !important;
}
.yellow-fill {
  fill: #ffe315 !important;
}
.gray-fill {
  fill: #bac1c5 !important;
}