.tabulator {
    padding-top: 10px;
}
.tabulator-selectable div{
    background-color: rgb(190, 38, 0);
}

.tabulator-selected div{
    background-color: #FFDFDF;
    color: black;
}

.tabulator-selected .tabulator-cell{
    -webkit-box-shadow: 0px 5px 4px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 5px 4px -3px rgba(0,0,0,0.75);
    box-shadow: 0px 5px 4px -3px rgba(0,0,0,0.75);
}
.tabulator .tabulator-header .tabulator-col.tabulator-sortable:hover {
    color: rgba(0, 0, 0, 0.7);
}

.rowHoverBackground div{
    background-color: #AFC2DE;
}

.tabulator-editing div{
    border-color: #192731;
}

.tabulator-header .tabulator-headers .tabulator-col{
    background-color: #343a40;
    /* color: white; */
    
}

.editBoxColor{
    border-color: #192731;
}

.tabOnClick{
    color: rgba(0, 0, 0, 0.2);
}

.eventos_crud{
    all: unset;
}


.eventos_crud i{
    padding: 2px 10px 2px 10px;
}

.eventos_crud:focus {
    outline: unset;
}

.eventos_crud:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10%;
}

.action-green{
    color: rgb(40, 167, 69);
    font-size: 1.3em;
}

.action-red{
    color: #e4566e;
    font-size: 1.3em;
}

.container-filter{
    width: 25%;
}
/*
background: none;
display: inline;
font-size: 100%;
font-family: inherit;
margin: 0;
padding: 0;
border: none;
font: inherit;
cursor: pointer;
outline: inherit;
*/